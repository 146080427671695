.not-for-ansatte:not(.vrtx-editor-frontpage) #main #vrtx-main-content .grid-container {
  .vrtx-frontpage-box.logos-bottom,
  .vrtx-frontpage-box.logos-bottom-new {
    position: inherit;
    height: 100px;
    margin-top: -80px;
    margin-bottom: -20px;

    .vrtx-box-content {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      padding: 0;
      height: 100px;

      p {
        display: flex;
        flex-wrap: nowrap;
        column-gap: 10px;
        padding: 0 60px;
        background: #f1f9fe;
        position: relative;
        z-index: 1;
      }

      a {
        //text-align: center;
        //padding: 0 12px;
        height: 100px;
       // min-width: 170px;

        &:focus,
        &:hover {
          background-color: #c7e8fb;
        }

        img {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
          width: auto;
          width: 100%;
        }
      }
    }
  }
  .vrtx-frontpage-box.logos-bottom {
    margin-top: 0;
    margin-bottom: -80px;
  }
}

.special-page-config .not-for-ansatte:not(.vrtx-editor-frontpage) #main #vrtx-main-content .grid-container .vrtx-frontpage-box.logos-bottom {
  margin-bottom: 0;
}

.vrtx-frontpage-two.not-for-ansatte:not(.vrtx-editor-frontpage) {
  #main #vrtx-main-content .grid-container {
    .vrtx-frontpage-box.logos-bottom-new {
      margin-top: -140px;
    }
  }
  &.no #main #vrtx-main-content .grid-container {
    .vrtx-frontpage-box.logos-bottom-new {
      margin-top: -80px;
      margin-bottom: 40px;
    }
  }
}

/*@media screen and (max-width: 718px) {*/
@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte:not(.vrtx-editor-frontpage) #main #vrtx-main-content .grid-container .vrtx-frontpage-box {
    &.logos-bottom, &.logos-bottom-new {
      position: inherit;
      height: auto;
      padding: 40px 0;
      margin-bottom: -20px !important;
      margin-top: 40px;
      background: #f1f9fe;

      .vrtx-box-content {
        position: relative;
        left: 0;
        right: 0;
        width: 100%;
        padding: 0;
        height: auto;

        p {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          row-gap: 20px;
          padding: 40px 0;
          background: #f1f9fe;
        }

        a {
          text-align: center;
          padding: 10px 20px;
          height: auto;
          max-width: 75%;

          img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            width: auto;
          }
        }
      }
    }
  }
}
