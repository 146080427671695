/*
 * Utilities
 */
/*
 * Commonly used mixins
 */
/*
 * Application specific configuration
 */
/*
 * Relative root for graphical profile folder.
 * Used mainly when LESS files are included from a different folder.
 */
/*
 * Utilities
 *
 */
// Issue with margin-top : main @import "frontpage.less";
// need to display h1 on pages without colored top: possibly fixed with new document-type
@import "components/frontpage-new.less";
/* fonts */
@font-face {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/hind-normal.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/hind-bold.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/nunitosans-semibold.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/nunitosans-bold.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.offscreen-screenreader,
.ui-helper-hidden-accessible {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

body {
  font-weight: 300;
  letter-spacing: 0.4px;
  color: @color-font;
  font-size: 18px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.vrtx-introduction {
  color: @color-font;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.ui-accordion.ui-widget h3.accordion,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: @color-dark;
}

h1 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.3;
  font-weight: 500;
}

.headline-large h2 {
  letter-spacing: 0.5px;
  font-size: 40px;
  line-height: 1.5;
}

h2 {
  font-weight: 500;
  line-height: 40px;
  line-height: 4rem;
  font-size: 30px;
  font-size: 3rem;
  letter-spacing: 0.5px;
  padding-bottom: 10px;
}

#vrtx-frontpage
  #vrtx-content:not(.frontpage-wide)
  #vrtx-main-content:not(.vrtx-empty-additional-content)
  .vrtx-frontpage-box[class*="third-box-"]
  h3,
h3 {
  font-size: 23px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 .ui-accordion.ui-widget .ui-accordion-header {
  font-family: "Hind", sans-serif;
  color: @color-headline;
  font-style: normal;
}

.vrtx-frontpage-full-width.total-main #main .vrtx-frontpage-box h2 {
  font-size: 30px;
  font-size: 3rem;
  line-height: 40px;
  line-height: 4rem;
}

h4 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.3;
  font-weight: 560;
}

.vrtx-search-results-container div.vrtx-search-results .result h2 a,
a {
  color: @color-link;
}

a.link-dark {
  color: @color-dark;
  font-size: 18px;
  font-weight: 500;
}
/* global link rules */
#main {
  a {
    text-decoration: underline;
  }
  /* exceptions */
  .button,
  .button a,
  .vrtx-back a,
  .vrtx-social-components a,
  h2 a {
    text-decoration: none;
  }
}

.ui-accordion.ui-widget .ui-accordion-header {
  color: @color-dark;
}

.button a {
  color: @color-dark;
}
/* hide message allert */
.uio-info-message.alert {
  background: transparent;
  height: 0;
}

.not-for-ansatte.header-search-collapsable .header-search-expand {
  background: url("../images/search.svg") no-repeat 11px center;
  background-size: 15px;
  padding: 7px 10px 7px 37px;
}
/* hide search on pageload */
.header-search {
  display: none;
}

.not-for-ansatte #head-wrapper {
  background: @color-light-blue;
  background: white;
  background: transparent;
  height: 100px;

  #head {
    height: 100%;
    position: relative;

    .partnerlogin {
      font-size: 1.5rem;
      margin-top: 1px;
      color: #2b2b2b;
    }

    .head-menu {
      right: 0;
      top: 35px;
      display: none; // appears to not be in use
      z-index: 11;

      .language {
        display: none;
      }

      .vrtx-login-manage-component a,
      ul li a {
        color: #2b2b2b;
      }

      .vrtx-login-manage-component .vrtx-dropdown-link {
        background: url("../images/drop-down-arrow.svg") 100% 53% no-repeat;
        background-size: 8px;
      }
    }

    a.header-search-expand {
      display: block;
      position: absolute;
      right: 0;
    }

    .vrtx-login-manage-component {
      display: none;
    }

    #header {
      height: 100%;

      span.logo {
        display: inline-block;
        height: 100%;
        padding: 16px 0;

        img {
          height: 100%;
        }

        a:focus img {
          outline: 3px solid @color-dark;
        }

        a:focus:not(:focus-visible) img {
          outline: none;
        }

        a:focus-visible img {
          outline: 3px solid @color-dark;
        }
      }

      .vrtx-login-manage-component a,
      ul li a {
        color: #949494;
      }

      a.head-logo:focus,
      a.head-logo:hover {
        color: #c7d9e0 !important;
        text-decoration: none;
      }
    }
  }

  .vrtx-frontpage-box-picture img {
    margin-top: -36px;
  }

  .uio-app-name {
    display: none;
  }
}
/* Search in header start */
.header-search-collapsed.not-for-ansatte
  #head-wrapper
  .header-search
  .header-search-expand {
  width: 0;
  overflow: hidden;
  padding-right: 0;
  top: 4px;
  height: 100%;
  background: url("../images/search-black.svg") no-repeat center center;
  background-size: 21px;
}

.not-for-ansatte #head-wrapper .header-search {
  input[type="text"] {
    width: 189px;
    background: @color-light;
    color: #4f4f4f;

    &:-moz-placeholder,
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder,
    &:focus:-ms-input-placeholder,
    &:focus::-moz-placeholder,
    &:focus::-webkit-input-placeholder {
      color: #2b2b2b !important;
    }
  }

  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    color: @color-dark;
    &:focus, &:hover {
      color: @color-dark;
    }
  }
}
/* end search */
/* breadcrumb */
div#content-breadcrumb-container {
  width: 970px;
  margin: 0 auto;
  padding: 1rem 0;
}

#main {
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  input[type="button"]:not(.red),
  input[type="cancel"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red) {
    background-color: #1a6667;
    &:focus, &:hover {
      background-color: #e4e4e4;
    }
  }
}



#globalnav {
  background: transparent;
  z-index: 100000000000000;
  margin-left: 420px;
  width: 970px;
  margin: -74px auto;
  font-family: @font-globalnav;

  .vrtx-tab-menu {
    width: auto;
    float: left;
    margin-left: 200px;
    padding-right: 27px;
    position: relative;

    li {
      position: relative;

      &:first-of-type {
        display: none;
      }
    }
  }

  ul li a,
  ul li.vrtx-active-item a {
    color: @color-font;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 10px 12px;
    margin-right: 14px;
    letter-spacing: 0.5px;

    &:focus,
    &:hover {
      background-color: transparent !important;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border: 3px solid;
      padding: 7px 9px;
    }
  }

  ul li.vrtx-active-item a {
    background-color: transparent !important;
    color: @color-font !important;
    padding-bottom: 12px;
    font-weight: 700;
  }
}

.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
  z-index: -1;
}

.not-for-ansatte
  #main
  .vrtx-frontpage-box.vrtx-header-false.vrtx-more-false.content-over-image-left
  .vrtx-box-content {
  margin-top: 40px;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    margin-bottom: 30px;
    margin-top: 10px;
  }
}

.contributors {
  font-size: 16px;
  font-size: 1.6rem;
}

.contributors img {
  border-radius: 75px;
}

.uio-main blockquote {
  position: relative;
}

blockquote {
  position: relative;
  border-left: none;
  font-family: inherit;
  font-size: 2rem;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 35px;
    height: 35px;
    background: url("../images/quotation-mark.svg") no-repeat center center;
    top: 6px;
    left: 0;
    border: none;
    margin: 0;
  }
}

#main .row-all-colored,
#main .vrtx-frontpage-box.grey-box,
#main .vrtx-frontpage-box.grey-box-light,
#main .vrtx-frontpage-box.grey-clip-box {
  background-color: #f5f5f5;
}

#main
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line) {
  .vrtx-frontpage-box.third-box-right {
    border-left: 2px solid #fcfcfc;
    border-left: 2px solid @color-light;
  }

  .vrtx-frontpage-box.third-box-left {
    border-right: 2px solid #fcfcfc;
    border-right: 2px solid @color-light;
  }
}

.vrtx-frontpage-full-width.total-main #main {
  #vrtx-change-language-link,
  #vrtx-frontpage-introduction,
  .row,
  .vrtx-back,
  .vrtx-context-box,
  h1 {
    padding-bottom: 20px;
    height: auto;
  }
}

#vrtx-structured-event .vrtx-date-info {
  display: none;
}

body #left-main .vrtx-breadcrumb-menu li {
  &:first-of-type a {
    border-top: none !important;
  }

  a {
    background-color: #f5f5f5;
  }

  &.vrtx-child {
    a {
      border-top: 2px solid rgba(207, 200, 188, 0.15);
    }

    a:before,
    span:before {
      color: #37689c;
    }

    a.vrtx-after-marked,
    a.vrtx-marked,
    span.vrtx-after-marked,
    span.vrtx-marked {
      border-top: none;
    }

    a.vrtx-marked,
    span.vrtx-marked {
      background: @color-primary;
    }
  }

  &.vrtx-ancestor {
    a:after,
    span:after {
      border-color: #f5f5f5 transparent transparent !important;
    }
  }

  a.vrtx-marked,
  span.vrtx-marked {
    background: @color-primary;
    color: @color-link;
  }
}

table th {
  font-weight: bold;
}
/* About - empluyees - page */
.not-for-ansatte
  #main
  #total-main
  .grid-container
  .vrtx-frontpage-box.about-page {
  padding-left: 20px;
  padding-right: 20px;

  .vrtx-box-content {
    padding: 30px 0;
  }

  .vrtx-frontpage-box-picture {
    padding-top: 30px;
    left: 20px;
    margin: 0;
  }

  .vrtx-frontpage-box-picture img {
    margin-left: 20px;
  }

  .vrtx-box-content {
    margin-left: 115px;
    padding-right: 0;
  }
}

.vrtx-frontpage-box.employee.neg-margin-top {
  margin-top: -80px;
}

.not-for-ansatte
  #main
  #total-main
  .grid-container
  .vrtx-frontpage-box.employee {
  .vrtx-frontpage-box-picture {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100px;
  }

  a {
    word-break: break-word;
  }
}
/* Links as sub menu - on about- and research-school page */
#main #total-main .grid-container .vrtx-frontpage-box.link-box {
  overflow: hidden;

  .vrtx-box-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    display: block;
    height: fit-content;
    margin-right: -10px;

    h2 {
      width: 33.33%;
      float: left;
      margin: 0;
      padding: 0;

      a {
        color: white;
        padding: 30px;
        display: block;
        background: @color-link;
        font-size: 24px;
        line-height: 1.3;
        margin: 0 10px 10px 0;
      }
    }

    &:after {
      content: "";
      display: none;
    }
  }

  &.research-school {
    h2 a {
      background: #583c6e;
      height: 100%;
      word-break: break-word;
      overflow: hidden;
      -webkit-box-orient: vertical;
      color: @color-light;
      padding: 40px;

      &:focus,
      &:hover {
        /*background: #694f7c;*/
        background: darken(#583c6e, 10%);
      }
    }
  }

  &.no-color {
    h2 {
      a {
        color: @color-dark;
        background: transparent;
        border: 4px solid @color-dark;

        &:focus,
        &:hover {
          background: @color-button-hover;
        }
      }
    }
  }
}
/* researh school */
.dlnrs-news-feed {
  .vrtx-box-content .vrtx-feed.with-images {
    .items::after {
      display: none;
    }
  }
}
/* research school button color */
body:not(.vrtx-editor-frontpage).not-for-ansatte #main.research-school #total-main {
  a.button,
  button,
  div.button {
    &:focus,
    &:hover {
      background: @color-research-school-button !important;
      background-color: @color-research-school-button !important;
    }
  }
}
/* news feed fronpage box */
a.all-messages {
  &::before {
    display: none;
  }
  width: 0;
  height: 0;
  overflow: hidden;

  &::after {
    width: 100%;
    content: "See all posts →";
    position: absolute;
    color: @color-dark;
    left: 0;
    font-size: 20px;
    margin-top: -47px;
  }

  &:hover :after {
    text-decoration: underline;
  }
}

.not-for-ansatte #main #total-main .news-feed .items {
  justify-content: start;

  li {
    margin-right: 2% !important;

    &:nth-child(3n) {
      margin-right: 0 !important;
    }
  }
}
/* events feed in frontpage box */
.not-for-ansatte
  #main
  #total-main
  .grid-container
  .vrtx-frontpage-box.events-feed {
  .vrtx-box-content .vrtx-event-component {
    justify-content: start;

    .vrtx-event-component-item {
      margin-left: 0;
      flex: none;
      margin-right: 2% !important;

      &:nth-child(3n) {
        margin-right: 0 !important;
      }

      .vrtx-event-component-picture {
        float: none;
        margin: 0;
        width: 100%;
        height: 150px;
        overflow: hidden;

        a.vrtx-image {
          img {
            min-width: 100%;
            width: 100%;
          }
        }
      }

      .content {
        background: #f1f9fe;
        background: #f6f6f6;
        //height: 232px;
        overflow: hidden;

        div.vrtx-event-component-title {
          padding: 0;
          height: auto;
          position: absolute;
        }

        .vrtx-event-component-title {
          text-decoration: none;
          font-weight: 500;
        }

        .vrtx-event-component-misc {
          margin: 0;
          color: @color-dark;
          font-family: "Hind", sans-serif;
        }

        .vrtx-event-component-misc {
          min-height: 78px;
        }
      }
    }
  }

  .vrtx-more {
    margin-bottom: 80px;
  }
}
/* Feeds: news feed & events feed */
#main #total-main .events-feed .vrtx-box-content div.vrtx-event-component,
#main #total-main .news-feed .items,
#main #total-main .vrtx-resources,
#vrtx-event-listing .vrtx-resources,
.not-for-ansatte #main .tagged-resources.vrtx-resources {
  display: flex;
  flex-wrap: wrap;

  .vrtx-event-component-item,
  .vrtx-last-event,
  .vrtx-resource,
  article.vrtx-resource,
  li {
    width: 32%;
    height: auto;
    border-bottom: none;
    flex: 0 32%;
    flex: 0 31.333%;
    flex: 0 31.333%;
    margin: 0 2% 2% 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .vrtx-event-component-picture {
      float: none;
      width: 100%;
      margin: 0;
    }

    .vrtx-event-component-picture a.vrtx-image,
    a.vrtx-image {
      width: 100%;
      min-height: 170px;
      max-height: 170px;
      overflow: hidden;
      margin: 0;
      position: relative;
      z-index: 100;
      display: block;
      width: 100%;
      float: none;

      img {
        max-width: 100%;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }

    * {
      margin-left: 0 !important;
    }

    a.item-title {
      margin-top: 150px;
    }

    a.item-title,
    a.vrtx-event-component-title,
    a.vrtx-title {
      text-decoration: none;
      color: @color-dark;
      font-size: 19px;
      line-height: 1.5;
      font-weight: 500;
      padding: 20px 0 10px;
      display: block;
      margin: 0;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }

    .publish-date,
    .published-date {
      font-size: 13px;
      text-transform: uppercase;
      color: #494952;
      font-weight: 400;
      font-family: "Hind", sans-serif;
    }

    .vrtx-event-component-misc {
      color: @color-dark;
      font-family: "Hind", sans-serif;
    }

    .description,
    .published-date,
    .vrtx-read-more,
    .vrtx-title {
      margin-left: 0;
    }

    .vrtx-read-more {
      display: none;
    }

    &:nth-of-type(even):last-of-type {
      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }

    &:nth-of-type(3n + 3) {
      margin-right: 0 !important;
    }
  }

  .vrtx-last-event {
    .vrtx-event-component-item,
    .vrtx-resource {
      width: auto;
      flex-grow: 1;
      margin-bottom: 0 !important;
      margin-right: 0 !important;
    }
  }
}
/* events feed */
/* event page */
#main .eventListing.ongoingEvents {
  margin-bottom: 60px;
}

#main .vrtx-resources {
  position: relative;

  h2 {
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    padding-bottom: 20px;
  }

  .vrtx-last-event,
  .vrtx-resource.vevent {
    display: inline;

    .description {
      display: none;
    }

    .time-and-place-container {
      color: @color-dark;
      font-family: "Hind", sans-serif;

      .location {
        overflow: hidden;
        display: block;
      }
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span.time-and-place-container {
      display: none;
    }

    .vrtx-number-of-comments-add-event-container {
      margin-top: 1em;
    }
  }

  .vrtx-last-event .vrtx-resource {
    margin: 0 !important;
  }
}
/* tags on event page */
.not-for-ansatte #main .tagged-resources.vrtx-resources {
  .vrtx-resource {
    a.vrtx-image ~ * {
      margin-left: 0;
    }

    .introduction {
      display: none;
    }

    .publish-date {
      margin-top: 5px;
    }
  }
}
/* researh school */
#bottomnav {
  display: none !important;
}

#comments-header-left a,
#main :not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
.add-comment-header {
  color: @color-link;
}
/* general elements */
/* search page */
.vrtx-textfield {
  input {
    border: 3px solid @color-dark;
    border-radius: 0;
  }
}

body #main #total-main form.vrtx-big-search {
  height: 52px;
  margin-top: 0;
  margin-bottom: 60px;

  input#bigSearchInputField {
    border-right: none;
    width: 260px;
    margin-right: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0;
    border: 3px solid @color-dark;
  }

  button#searchbutton {
    padding: 10px 20px;
    border: none;
    background: transparent;
    color: @color-dark;
    border: 3px solid @color-dark;
    position: relative;
    border-top-left-radius: 0;

    &::before {
      display: none;
    }

    &:focus,
    &:hover {
      background: @color-button-hover;
    }
  }
}

#searchbutton:after {
  display: none;
}

body #main #total-main #vrtx-advanced-search-submit-hide {
  a.button {
    background: transparent;
    color: @color-dark;
    border: 3px solid @color-dark;

    &:focus,
    &:hover {
      background: @color-button-hover;
    }
  }
}
/* 404 page */
form.searchform fieldset {
  #searchstring,
  #searchstring2 {
    border: 3px solid;
    border-radius: 0;
    height: 50.2px;
  }
}
/* accordion */
.ui-accordion.ui-widget .ui-accordion-content {
  color: @color-dark;
}
/** fix height when accordion */
.vrtx-frontpage-box.accordion {
  height: 100% !important;
}
/* fix padding on all pages */
#main
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line).row-all-colored {
  padding: 60px 0;
}

#main .grid-container {
  margin-bottom: 60px;
}

#main .grid-container:not(.row-thirds-double) { 
  .grey-box > :first-child,
  .grey-box-light > :first-child,
  .red > :first-child,
  &.row-one-colored .vrtx-frontpage-box > :first-child {
    padding-top: 60px;
  }
}

#main .grid-container:not(.row-thirds-double) {
  .grey-box> :last-child,
  .grey-box-light> :last-child,
  .grey-box-light.content-over-image-left .vrtx-frontpage-box-picture:nth-last-child(2),
  .grey-box-light.content-over-image-right .vrtx-frontpage-box-picture:nth-last-child(2),
  .grey-box-light.featured-one-big-two-column .vrtx-featured-item-1 .vrtx-frontpage-box-picture:nth-last-child(2),
  .grey-box.content-over-image-left .vrtx-frontpage-box-picture:nth-last-child(2),
  .grey-box.content-over-image-right .vrtx-frontpage-box-picture:nth-last-child(2),
  .grey-box.featured-one-big-two-column .vrtx-featured-item-1 .vrtx-frontpage-box-picture:nth-last-child(2),
  .red> :last-child,
  .red.content-over-image-left .vrtx-frontpage-box-picture:nth-last-child(2),
  .red.content-over-image-right .vrtx-frontpage-box-picture:nth-last-child(2),
  .red.featured-one-big-two-column .vrtx-featured-item-1 .vrtx-frontpage-box-picture:nth-last-child(2),
  .row-one-colored .vrtx-frontpage-box> :last-child,
  .row-one-colored .vrtx-frontpage-box.content-over-image-left .vrtx-frontpage-box-picture:nth-last-child(2),
  .row-one-colored .vrtx-frontpage-box.content-over-image-right .vrtx-frontpage-box-picture:nth-last-child(2),
  .row-one-colored .vrtx-frontpage-box.featured-one-big-two-column .vrtx-featured-item-1 .vrtx-frontpage-box-picture:nth-last-child(2) {
    padding-bottom: 60px;
  }
}
/* vortex-more button */
div.vrtx-more a {
  /*font-size: 16px;
  font-size: 1.6rem;*/
  &:before {
    display: none;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

#main .vrtx-frontpage-box div.vrtx-more a {
  text-decoration: none;
  color: @color-dark;
  font-size: 20px;
  font-size: 2rem;

  &::before {
    display: none;
  }
}
/*  button */
/* header button */
.not-for-ansatte.header-search-collapsable .header-search {
  z-index: 10;
  top: 0;
  position: relative;
  float: left;

  a:focus,
  a:hover {
    outline: 3px solid @color-dark;
    border-radius: 0;
  }

  form {
    position: absolute;
    min-width: 292px;
    background: white;
    padding: 10px 10px 10px 0;
    right: -40px;
    top: -10px;

    fieldset {
      padding: 0;
      margin: 0;
      border-radius: 0;
    }
  }
}

.not-for-ansatte #head-wrapper #globalnav .header-search {
  #search-string {
    border-radius: 0;
    border: 2px solid @color-dark;
  }

  button {
    background-color: transparent;
    border: 2px solid;
    border-radius: 0;
    padding: 5px 10px;

    &:focus,
    &:hover {
      background-color: @color-button-hover;
      border: 2px solid;
    }
  }
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.filter):not(.red):not(.white):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
#main input[type="button"]:not(.red),
#main input[type="cancel"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red) {
  background-color: @color-button;
  border: 3px solid @color-dark;
  border-radius: 0;
  background-image: none;
  padding: 9px 20px;
  font-weight: 400;
  color: @color-dark;

  p {
    margin: 0;
  }
}

#main .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white),
#main input[type="button"]:not(.red),
#main input[type="cancel"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red),
.not-for-ansatte #head-wrapper .header-search button {
  &focus,
  &:hover {
    background-color: @color-button-hover;
    color: @color-dark;

    a {
      color: @color-dark;
    }
  }
}
/* Borders */
#vrtx-event-listing #main .vrtx-resources > h2:first-child {
  border-top: none;
}

#main .vrtx-paging-feed-wrapper {
  border-top: 4px solid @color-borders;

  a {
    text-decoration: none;
  }
}

#vrtx-structured-event .vrtx-introduction {
  border-bottom: none;
}

.vrtx-authors,
.vrtx-date-info,
.vrtx-tags {
  border-top: 4px solid @color-borders;
}

.ui-accordion.ui-widget .ui-accordion-header .ui-icon {
  display: inline-block;
  background: url("../images/icon-chevron-right-black.svg") center left
    no-repeat;
  background-size: auto 100%;
  width: 30px;
  height: 1em;
  margin-bottom: -0.1em;
  margin-left: -30px;
  position: static;
}

.ui-accordion.ui-widget
  .ui-accordion-header.ui-accordion-header-active
  .ui-icon {
  background-image: url("../images/icon-chevron-down-black.svg");
}
/* Arrow icons */
#main .vrtx-back a:before {
  display: inline-block;
  height: 1em;
  width: 1em;
  padding-right: 10px;
  background: url("../images/icon-chevron-back-blue-sfdl.svg") 0 0.2ex no-repeat;
  background-size: auto 100%;
  content: " ";
}
/* fix img width from uio-style */
.vrtx-frontpage-box[class*="half-box-"].vrtx-frontpage-box-picture
  .vrtx-frontpage-box-picture
  img,
.vrtx-frontpage-box[class*="third-box-"].vrtx-frontpage-box-picture:not(.third-box-double)
  .vrtx-frontpage-box-picture
  img {
  width: auto;
}
/* list style */
.grid-container ul > li:before,
.uio-main ul > li:before {
  content: "\25A0";
  color: @color-dark;
  margin-left: -2.5ex;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  overflow: hidden;
  font-size: 0.8em;
}
/* fix grey color */
.lastModified,
.published-date,
.vrtx-authors,
.vrtx-byline,
.vrtx-comment .comment-date,
.vrtx-date-info,
.vrtx-event-component-misc,
.vrtx-featured-item .vrtx-date,
.vrtx-image-creation-time,
.vrtx-message-line-publish-date,
.vrtx-resource .duration,
div.time-and-place-container {
  color: @color-dark;
}
@import "components/footer.less";
/* Footer */
#footer-wrapper {
  padding: 80px 0 60px;
  background: @color-primary-grey;
  color: @color-font;
  position: relative;
  font-size: 1.6rem;

  h2 {
    color: @color-font;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
  }

  #footers {
    padding-bottom: 20px;
    background: none;

    img.logo-footer {
      max-width: 200px;
      padding: 30px 0;
    }

    a {
      color: @color-font;
    }

    .col-3-5 {
      padding-bottom: 50px;
      width: 80%;

      .content-left,
      .content-right {
        float: left;
      }

      .content-left {
        width: 60%;
        padding-right: 30px;
      }

      .content-right {
        width: 40%;
      }

      a.language-link {
        text-decoration: none;
        font-size: 18px;
        font-size: 1.8rem;
        line-height: 1.3;
        cursor: pointer;
      }

      .social-components {
        width: 100%;
        text-align: left;
        bottom: 0;
        padding-top: 72px;

        a {
          margin-right: 15px;
          width: 45px;
          height: 45px;
          display: inline-block;

          &.facebook {
            border: 4px solid #0e1d1f;
            color: #0e1d1f;
            display: inline-block;
            background: #0e1d1f url("../images/facebook-icon-white.svg")
              no-repeat center center;
            background-size: 60%;
          }

          &.twitter {
            border: 4px solid #0e1d1f;
            color: #0e1d1f;
            display: inline-block;
            background: #0e1d1f url("../images/twitter-icon-white.svg")
              no-repeat center center;
            background-size: 60%;
          }

          &.linkedin {
            border: 4px solid #0e1d1f;
            color: #0e1d1f;
            display: inline-block;
            background: #0e1d1f url("../images/linkedin.svg") no-repeat center
              center;
            background-size: 55%;
          }
          &.youtube {
            border: 4px solid #0e1d1f;
            color: #0e1d1f;
            display: inline-block;
            background: #0e1d1f url("../images/youtube-icon-white.svg") no-repeat center
              center;
            background-size: 70%;
          }
        }
      }
    }

    .col-2-5.responsible-login {
      width: 20%;

      .vrtx-login-manage-component {
        position: absolute;
        right: 0;
      }

      .content {
        margin-left: 140px;
        margin-right: 0;

        p:first-of-type,
        span.menu-label {
          display: none;
        }
      }
    }
  }

  .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
    background: url("../images/down-arrow-black.svg") center left no-repeat;
    background-size: 10px;
  }
}
/*
@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {*/
/*@media screen and (max-width: 718px) {*/
@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  h1 {
    font-size: 30px;
    line-height: 1.5;
    margin-top: 40px;
  }

  #vrtx-content h1 {
    margin-top: 0;
  }

  h2 {
    font-size: 20px;
    font-size: 2rem;
    line-height: 30px;
    line-height: 3rem;
  }

  #vrtx-frontpage #main .vrtx-frontpage-box,
  #vrtx-frontpage #main h1 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .not-for-ansatte #head-wrapper #head .head-menu {
    top: 0;
    display: none;
  }

  .menu-search {
    input {
      border: 1px solid @color-dark;
      border-radius: 0;
    }

    button {
      background: #c8efee;
      color: @color-dark;
      border-radius: 0;

      &:focus,
      &:hover {
        background: rgba(200, 239, 238, 0.88);
      }

      &::after,
      &::before {
        display: none;
      }
    }
  }

  .not-for-ansatte #offcanvas-outer-wrapper #head-wrapper #head {
    height: auto;
    height: 100px;

    #header {
      height: 100%;
      padding: 0;

      .logo {
        padding: 16px;

        a {
          display: block;
        }

        img {
          width: 150.2px;
          height: 68px;
        }
      }
    }
  }

  .not-for-ansatte #head-wrapper {
    height: 165.781px;
    height: fit-content !important;
    height: -moz-fit-content !important;
    border-bottom: none;
    display: block;

    #menu-wrapper {
      .menu {
        background: @color-primary-grey;
        background: @color-light;

        li a {
          color: @color-dark;
        }

        #toggle-navigation {
          .icon {
            background: url("../images/nav-icon.svg") 0 -4px no-repeat;
          }
        }

        #toggle-search {
          .icon {
            background-image: url("../images/search-icon-active-black.svg");
            background-size: contain;
          }
        }

        li.active {
          background: @color-button-hover;
        }
      }
    }
  }

  #nav-offcanvas li #lang-link {
    display: none;
  }

  .vrtx-introduction {
    font-size: 18px;
    line-height: 1.6;
  }

  #vrtx-frontpage #main #vrtx-main-content .grid-container {
    border-top: none;
    border-bottom: none;

    .row {
      .vrtx-frontpage-box {
        border-top: none;
        border-bottom: none;
      }
    }
  }
  /* feeds */
  .not-for-ansatte #main #total-main {
    .vrtx-feed.with-images ul li a.vrtx-image:first-child {
      margin: 0;
      max-height: 190px;
      min-height: auto;
      /*height: 100%;*/
      img {
        max-width: none;
        width: 100%;
        height: auto;
        max-height: none;
      }
    }
    // feeds
    #vrtx-event-listing .vrtx-resources,
    .events-feed .vrtx-box-content div.vrtx-event-component,
    .news-feed .items,
    .vrtx-resources-frontpage {
      display: block;

      .vrtx-event-component-item,
      .vrtx-last-event,
      .vrtx-resource,
      article.vrtx-resource,
      li {
        width: 100%;
        float: none;
        display: block;
        margin: 0 0 !important;

        a.vrtx-image,
        a.vrtx-image:first-child {
          margin: 0;
        }

        a.vrtx-image img {
          width: 100%;
          max-height: none;
        }

        &:after {
          display: none;
        }

        &:nth-of-type(3n + 3) {
          margin-right: auto !important;
        }
      }

      .vrtx-event-component-item:nth-of-type(2n):last-of-type {
        display: block;
      }
    }
  }

  .not-for-ansatte
    #main
    #total-main
    .grid-container
    .vrtx-frontpage-box.events-feed
    .vrtx-box-content
    .vrtx-event-component
    .vrtx-event-component-item {
    margin-left: 0;
    margin-right: auto !important;
  }

  .not-for-ansatte
    #main
    #total-main
    .grid-container
    .vrtx-frontpage-box.events-feed
    .vrtx-box-content
    .vrtx-event-component
    .vrtx-event-component-item:nth-of-type(3),
  .not-for-ansatte #main #total-main .news-feed .items li:nth-of-type(3) {
    margin-right: auto !important;
  }

  .not-for-ansatte #main #total-main .news-feed .items li {
    display: block !important;
  }

  #main #total-main .vrtx-resources .vrtx-last-event,
  #main #total-main .vrtx-resources .vrtx-resource,
  #main #total-main .vrtx-resources article.vrtx-resource {
    width: 100%;
    float: none;
    display: block;
    height: auto !important;
    flex: none;

    a.vrtx-image img {
      width: 100%;
      max-height: none;
    }

    a.vrtx-title {
      color: @color-dark;
      font-size: 19px;
      line-height: 1.5;
      font-weight: 500;
      display: block;
      margin: 0;
    }

    .content {
      height: auto !important;
    }

    .vrtx-number-of-comments-add-event-container {
      position: relative;
      padding-top: 15px;
      width: auto;
      background: transparent;
      bottom: 0;
    }
  }
  /* employees */
  .not-for-ansatte #main #total-main .grid-container {
    .vrtx-frontpage-box.about-page .vrtx-frontpage-box-picture img {
      margin-left: 0;
    }

    .vrtx-frontpage-box.employee {
      .vrtx-frontpage-box-picture {
        left: 20px;
      }

      &.neg-margin-top {
        margin-top: 0;
      }

      &.grey-box {
        padding: 20px;

        .vrtx-frontpage-box-picture {
          padding: 40px 0;
        }
      }
    }
  }
  /* search page */
  body #main #total-main form.vrtx-big-search {
    height: auto;
    margin-top: 0;
    margin-bottom: 60px;

    input#bigSearchInputField {
      width: auto;
    }
  }

  #footer-wrapper {
    padding: 60px 0;

    #footers {
      padding: 30px 20px;

      .col-3-5.vcard {
        width: 100%;

        h2 {
          font-size: 20px;
        }

        .content-left,
        .content-right {
          width: 100%;
          padding-right: 0;
        }

        .content-right {
          padding-top: 40px;
        }

        .social-components {
          padding-top: 40px;
        }
      }

      .col-2-5.responsible-login {
        width: 100%;

        .vrtx-login-manage-component {
          left: 20px;
          bottom: 40px;
        }
      }
    }
  }
}
/*------------------------------------*\
# COLOR
\*------------------------------------*/
@color-primary: #c7e8fb;
@color-primary-grey: #ececec;
@color-light-blue: #e4f4fe;
@color-button: transparent;
@color-button-hover: #c9efef;
@color-research-school-button: #b8a3ce;
@color-borders: @color-primary-grey;
@color-link: #1f5491;
@color-font: @color-dark;
@color-headline: @color-dark;
@color-dark: #000;
@color-light: #ffffff;
/*------------------------------------*\
#Font
\*------------------------------------*/
@font-globalnav: "Nunito Sans", sans-serif;
