/* Research frontpage */
// colors //
@color-dark: #000000;
@color-light: #ffffff;

#main .grid-container .row.flex-container {
  margin: 0 auto;

  &:before {
    content: "";
    display: none;
  }

  &::after {
    display: none;
  }
}

.vrtx-frontpage-box.research .vrtx-box-content {
  margin-top: 0 !important;
  top: 0;

  h2 {
    padding-top: 40px;
    font-size: 40px;
    line-height: 1.5;
  }
}

.vrtx-frontpage-box.filter {
  opacity: 0;
}

.not-for-ansatte #main.research #total-main {
  .grid-container {
    margin-bottom: 0;

    .row {
      padding: 0;
    }

    &.full-width-picture-bg {
      .full-width-picture.research {
        .vrtx-box-content {
          padding-bottom: 140px;
        }
      }
    }

    &.filter {
      margin-top: -123px;
      background: transparent;

      .vrtx-frontpage-box.filter-new {
        margin-top: 0;
        z-index: 10;
        margin-bottom: 0;

        .vrtx-box-content {
          padding: 40px 0 50px;

          p:first-of-type {
            display: inline;
            float: left;
          }

          p {
            margin-top: 0;
            display: inline-block;
          }
        }

        a.filter {
          border: none;
          color: @color-dark;
          border-radius: 0;
          margin: 0 5px;
          background-color: transparent !important;
          font-weight: 500;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          padding: 7px 15px;
          cursor: pointer;

          &.active,
          &:active,
          &:focus,
          &:hover {
            padding: 5px 13px;
            border: 2px solid;
            text-decoration: none;
          }

          &:focus {
            outline: 1px solid @color-dark;
          }

          &:focus:not(:focus-visible) {
            outline: none;
          }

          &:focus-visible {
            outline: 1px solid @color-dark;
          }
        }
      }
    }

    &:nth-of-type(3) {
      margin-top: 0;
      margin-left: 0;

      .row {
        &.flex-container {
          width: 100%;
          display: flex;
          position: relative;
          flex-wrap: wrap;
          justify-content: flex-start !important;
          margin-left: 0;

          .url-wrap {
            -webkit-animation: fadein 0.2s;
            animation: fadein 0.2s;
            background: @color-light;

            &:focus {
              .vrtx-box-content {
                h2 {
                  text-decoration: underline;
                }
              }
            }

            &:focus:not(:focus-visible) {
              .vrtx-box-content {
                h2 {
                  text-decoration: none;
                }
              }
            }

            &:focus-visible {
              .vrtx-frontpage-box-picture img {
                filter: saturate(1) !important;
              }

              .vrtx-box-content {
                h2 {
                  text-decoration: underline;
                }
                background: rgba(0, 0, 0, 0.14) !important;
              }
            }

            &:nth-child(even) {
              background: #97d4d7;
            }

            &.active:nth-child(even) {
              background: @color-light;
            }

            &.active-marine:nth-of-type(even), &.active-marine:nth-of-type(odd) {
              background: #d4f7ff;
            }

            &.active-industry:nth-of-type(even), &.active-industry:nth-of-type(odd) {
              background: #f6f6f6;
            }

            &.active-land:nth-of-type(even), &.active-land:nth-of-type(odd) {
              background: #a6d5c6;
            }

            &.active-rri:nth-of-type(even), &.active-rri:nth-of-type(odd) {
              background: #ede8f4;
            }

            &.active-health:nth-of-type(even), &.active-health:nth-of-type(odd) {
              background: #f1f9fe;
            }
            background: @color-light;
            margin: 0;
            flex: 0 33.333%;
            width: 33.333%;

            .vrtx-frontpage-box.box {
              min-height: auto !important;
              max-height: none !important;
              width: 100%;
              height: auto !important;
              padding: 0;
              overflow: hidden;

              .vrtx-frontpage-box-picture {
                min-height: auto;
                max-height: none;
                height: auto;
                margin-top: 0;
                position: relative;
                float: none;
                overflow: hidden;
                opacity: 1;
                left: 0;
                width: 50%;

                a {
                  float: left;
                  width: 100%;
                  height: 221.94px;
                  overflow: hidden;
                }

                img {
                  width: auto;
                  min-width: 100%;
                  min-height: 100%;
                  max-width: 100%;
                  object-fit: cover;
                  max-height: none;
                  filter: saturate(0.15);
                  opacity: 1;
                  margin-bottom: -9px;
                }
              }

              .vrtx-box-content {
                margin: 0;
                height: 100%;
                display: block;
                width: 50%;
                min-height: 100%;
                max-height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background: rgba(255, 255, 255, 0.55);
                overflow: hidden;
                padding-top: 30px;
                padding-left: 30px;
                padding-right: 30px;

                h2,
                p {
                  color: @color-dark;;
                }

                h2 {
                  letter-spacing: 1px;
                  margin-top: 6px;
                  font-size: 2.7rem;
                  line-height: 1.2;
                  text-rendering: geometricPrecision;
                  font-weight: 700;
                  position: relative;
                  margin: 0;
                  padding: 0 0 15px;
                  text-overflow: ellipsis;
                }

                p {
                  height: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-weight: 400;
                  letter-spacing: 0.5px;
                  line-height: 1.5;
                  font-size: 15px;
                  display: inline-block;
                  background: transparent;
                }

                &::after {
                  display: none;
                }
              }

              .vrtx-more {
                display: none;
              }

              &:focus .vrtx-box-content,
              &:hover .vrtx-box-content {
                background: rgba(0, 0, 0, 0.14);

                h2 {
                  text-decoration: underline;
                }
              }

              &:focus .vrtx-frontpage-box-picture img,
              &:hover .vrtx-frontpage-box-picture img {
                opacity: 1;
                filter: saturate(1);
              }

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1700px) {
  .not-for-ansatte
    #main.research
    #total-main
    .grid-container:nth-of-type(3)
    .row.flex-container
    .url-wrap
    .vrtx-frontpage-box.box {
    .vrtx-box-content {
      padding: 25px 25px 0;
      border-bottom: 20px solid transparent;

      h2 {
        font-size: 2.2rem;
        padding-bottom: 10px;
      }
    }
  }
}
@media only screen and (max-width: 1530px) {
  .not-for-ansatte
    #main.research
    #total-main
    .grid-container:nth-of-type(3)
    .row.flex-container
    .url-wrap {
    width: 50% !important;
    flex: 0 50%;

    .vrtx-frontpage-box.box {
      background: #e8f8f7;

      .vrtx-box-content {
        padding: 40px 30px 20px;
        border-bottom: none;

        h2 {
          margin-top: 0;
          font-size: 2.8rem;
        }
      }
    }

    &:nth-of-type(even) {
      background: transparent;
    }
  }
}
@media only screen and (max-width: 1330px) {
  .not-for-ansatte
    #main.research
    #total-main
    .grid-container:nth-of-type(3)
    .row.flex-container
    .url-wrap {
    width: 50% !important;
    flex: 0 50%;

    .vrtx-frontpage-box.box {
      .vrtx-box-content {
        padding: 25px;
        border-bottom: 10px solid transparent;

        h2 {
          margin-top: 0;
          font-size: 2.2rem;
        }
      }

      .vrtx-more {
        display: none;
        padding: 5px 10px !important;
      }
    }

    &:nth-of-type(even) {
      background: transparent;
    }
  }
}
@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  /*@media screen and (max-width: 718px) {*/
  .vrtx-frontpage-box.filter {
    padding: 20px 20px 40px;

    .vrtx-box-content p {
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;

      button.filter {
        flex: 0 47%;
        margin: 1.5%;
        font-size: 16px;
        line-height: 1.8;
      }
    }
  }

  a.url-wrap {
    width: 100%;
    display: block;
    flex: none;
    height: auto;
    float: none;
    padding: 0 20px 20px;
    margin-bottom: 0;
    color: @color-dark;

    .vrtx-frontpage-box.box .vrtx-box-content {
      p {
        height: auto;
        max-height: none;
      }

      &::after {
        display: none;
      }
    }
  }
  /* research page with .research class generated in js-document */
  #vrtx-frontpage #main.research #total-main {
    .grid-container.full-width-picture-bg {
      background-color: #98d4d8 !important;

      .vrtx-frontpage-box .vrtx-box-content {
        padding: 20px 0 40px;
      }
    }

    .grid-container:nth-of-type(2) {
      .row {
        margin-top: 20px;
        margin-top: 0;

        .filter.filter-new {
          padding-bottom: 0;
          margin-bottom: 40px;
          padding-top: 0;

          .vrtx-box-content {
            padding: 20px 0;

            p:first-of-type {
              display: block;
              width: 100%;
            }

            a.filter {
              float: left;
              margin-top: 10px;
            }
          }
        }
      }
    }

    .grid-container:nth-of-type(3) .row.flex-container .url-wrap {
      width: 100% !important;
      flex: none;
      background-color: transparent;

      &:nth-of-type(odd) {
        background: transparent !important;
      }

      &:nth-of-type(even) {
        background: transparent !important;
      }

      .vrtx-frontpage-box.box {
        background: transparent;
        padding: 0;

        &:focus,
        &:hover {
          background: transparent !important;
          background-color: transparent !important;
          .vrtx-box-content {
            background: transparent;
            background-color: transparent !important;
          }
        }

        .vrtx-frontpage-box-picture {
          width: 100%;
          margin: 0 auto;
          max-height: 240px;
        }

        .vrtx-box-content {
          width: 100%;
          margin: 0 auto;
          position: relative;
          padding: 15px 0;
          border-bottom: 37px solid transparent;
          h2 {
            font-size: 2.2rem;
          }
        }
        .vrtx-more {
          padding: 5px 10px !important;
        }

        &:focus,
        &:hover {
          background: #93d3d3ba;
          .vrtx-more {
            display: none !important;
          }
        }
      }
    }
  }
}
