/* CSS for Centre for Digital life Norway */
@import "research.less";
@import "project.less";
@import "conference.less";

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//Removing #head and vrtx-back in editmode
.vrtx-editor-frontpage {
  &#vrtx-frontpage.vrtx-frontpage-two.not-for-ansatte #main {
    &.project #total-main .vrtx-back {
      display: none;
    }
    &.conference {
      #total-main {
        .grid-container {
          margin-bottom: 60px;
        }
      }
    }
  } 
}

.vrtx-mode-edit {
  .project-overview {
    opacity: 1;
  }
  #head-wrapper {
    display: none;
  }
}

body:not(.vrtx-editor-frontpage)#vrtx-frontpage.vrtx-frontpage-two {
  &.not-for-ansatte {
    #head-wrapper {
      position: absolute;
      width: 100%;
    }
    
    &.vrtx-frontpage-full-width.total-main #main {
      width: 100%;
      max-width: 100%;
      #total-main .grid-container {
        &:first-of-type {
          margin-bottom: 80px;
        }
        .row {
          padding-bottom: 20px;
          >h2 {
            margin-bottom: 40px;
            margin-top: 20px;
          }
          &.flex-container {
            padding-bottom: 0;
          }
        }
        &.full-width-picture-bg {
          .vrtx-box-content {
            padding-bottom: 60px;
          }
        }
        &.no-padding-top .row {
          padding-top: 0;
        }
        .vrtx-frontpage-box.big-padding-top .vrtx-box-content {
          padding-top: 80px;
        }
      }
      &.conference-frontpage-new {
        #total-main .grid-container {
          margin-bottom: 60px;
          &.row-all-no-margin-bottom,
          &.full-width-picture-bg {
            margin-bottom: 0;
          }
        }
      }
      &.research {
        #total-main .grid-container {
          &.full-width-picture-bg {
            padding-bottom: 60px;
          }
          &.filter {
            margin-top: -178px;
            .vrtx-frontpage-box.filter-new {
              .vrtx-box-content {
                padding: 15px 0 30px;
              }
            }
          }
        }
      }
      &.conference-frontpage {
        #total-main .grid-container {
          margin-bottom: 0;
        }
      }
      &.project {
        margin-top: 0;
        #total-main .grid-container {
          margin-bottom: 60px;
          &.full-width-picture-bg {
            margin-bottom: 30px;
            padding-bottom: 100px;
            .full-width-picture.project {
              h2 {
                font-size: 7.5rem;
              }
              p {
                font-size: 2.6rem;
              }
            }
          }
          &:last-of-type {
            margin-bottom: 80px;
          }
          .row {
            padding-bottom: 20px;
          }
          &.grid-project-overview {
            margin-bottom: 0;
            &.last {
              margin-bottom: 30px;
            }
            .row {
              padding: 0;
            }
          }
        }
      }
    }
    &.the-frontpage.vrtx-frontpage-full-width.total-main #main {
      #total-main .grid-container.full-width-picture-bg {
       .row {
         padding: 0;
       }
     }
   }

    /* fix for new forsidemal-css */
    #main {
      max-width: 970px;
      width: 970px;
      margin: 140px auto 0 auto;
      #total-main {
        #vrtx-content,
        #vrtx-main-content,
        #vrtx-main-content #vrtx-content {
          width: 100%;
        }
        #vrtx-main-content {
          &.vrtx-empty-additional-content,
          &.vrtx-hide-additional-content-true,
          &:last-child {
            width: 100%;
          }
        }
        .grid-container {
          &.row-all-colored.row-first-colored {
            background-color: @color-grey-row;
            .vrtx-frontpage-box.grey-box {
              background-color: @color-grey-row;
              .all-messages::after {
                background: @color-grey-row;
              }
            }
          }
          &.no-margin-top {
            margin-top: 0;
          }
        }
      }

      &.project,
      &.research,
      &.frontpage,
      &.conference,
      &.conference-frontpage-new {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }
      &.frontpage {
        margin: 40px auto 0 auto;

        #total-main {
          .grid-container:not(.full-width-picture-bg) .row {
            padding: 0 0 20px 0;
          }
        }
      }

      &.conference {
        #total-main {
          .grid-container {
            &:not(.full-width-picture-bg) .row {
              padding: 20px 0;
            }
            &.full-width-picture-bg .vrtx-frontpage-box h2 {
              font-size: 6rem;
            }
          }
        }
      }

      &.conference-frontpage-new,
      &.conference {
        #total-main .grid-container {
          &.full-width-picture-bg {
            margin-top: -40px;
          }
          &:not(.full-width-picture-bg) .row {
            padding: 0 0 20px 0;
          }
          &:first-of-type {
            .row {
              .vrtx-frontpage-box {
                padding: 0 0 40px 0;
              }
            }
          }
          &.grid-color-blue {
            background: @color-grid-dark;
            .row {
              padding: 20px 0;
            }
          }
          &.grid-color-lightblue {
            background: @color-grid-light;
            .row {
              padding: 20px 0;
            }
          }
          &.row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line).row-all-colored {
            padding: 0;
          }
        }
      }
      &.research-school {
        max-width: 100%;
        width: 100%;
        #total-main .grid-container:not(.full-width-picture-bg) .row {
          padding: 20px 0;
        }
      }
      
      &.research-school-frontpage {
        #total-main .grid-container:not(.full-width-picture-bg) .row {
          padding: 0 0 40px 0;
        }
      }
    }
     
    &.the-frontpage #head-wrapper,
    #head-wrapper.frontpage {
      position: absolute;
      width: 100%;
      z-index: 10000;
      background: @color-light;
      background: linear-gradient(180deg,
          rgba(179, 225, 230, 0.7),
          rgba(179, 225, 230, 0.4),
          rgba(179, 225, 230, 0.2),
          rgba(179, 225, 230, 0.1),
          rgba(179, 225, 230, 0));
      height: 200px;
      #head {
        height: 100px;
      }
    }

    .vrtx-frontpage-box.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
      margin: 0;
    }

    /* frontpage norwegian */
    &.no #main #vrtx-main-content .grid-container.full-width-picture-bg:nth-of-type(1) .frontpage-first-box .vrtx-box-content {
      a.button {
        width: fit-content;
        display: block;
        margin-top: 20px;
        padding: 5px 25px 3px;
      }
    }

    // class added to frontpage-box and moved to .grid-container by script
    #main .grid-container.negative-margin {
      margin-top: -140px;
    }
  }

  /* index-page (frontpage) */
  &.not-for-ansatte.the-frontpage #main,
  &.not-for-ansatte #main.frontpage {
    #total-main #vrtx-main-content .grid-container {
      margin-top: -80px;
      margin-bottom: 80px;
      &:has(.logos-bottom-new) {
        margin-top: -100px;
      }

      .flex div {
        margin-bottom: 30px;
        margin-top: 30px;
      }

      // first image on frontpage - full background
      &.full-width-picture-bg:nth-of-type(1) {
        background-position: center center !important;
        min-height: 550px;

        .frontpage-first-box {
          .vrtx-box-content {
            background: transparent;
            width: 100%;
            position: relative;
            padding-bottom: 80px;

            h2 {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-family: "Hind", sans-serif;
              font-weight: 500;
              text-shadow: 0 1px 2px @color-text-bg-light;
              text-rendering: geometricPrecision;
              font-size: 50px;
              font-size: 5rem;
              line-height: 1.3;
              padding-bottom: 20px;
              /* if not white header */
              padding-top: 80px;
              margin: 0 auto;
            }

            img {
              position: absolute;
              right: -8%;
              top: 0;
              width: 45%;
              opacity: 0.9;
            }

            p {
              font-size: 20px;
              font-family: "Hind", sans-serif;
              line-height: 30px;
              font-weight: 400;
              color: @color-text;
            }
          }

          &.font-dark {
            .vrtx-box-content {
              h2,
              p {
                color: @color-dark;
              }
            }

            &.txt-bg p {
              line-height: 2;
              display: inline;
              background: @color-text-bg-light;
            }
          }

          &.font-light {
            .vrtx-box-content {
              h2,
              p {
                color: @color-light;
              }
            }

            &.txt-bg {
              p:not(.transparent) {
                line-height: 2;
                display: inline;
                background: @color-text-bg-dark;
              }
              a.button {
                overflow: hidden;
                color: @color-dark;
                background: transparent;
                border: 3px solid @color-dark;
                &:focus,
                &:hover {
                  background: @color-button-bg;
                }
              }
            }
          }
        }
      }
      
      /* end full width picture */
      /* IF banner fullwidth: */
      &.full-width-picture-bg.grid-banner {
        margin: 0 auto 160px;
        width: 970px;
        padding: 0;
        min-height: auto;
        background-size: 100% !important;

        .vrtx-frontpage-box {
          height: 100%;
          width: 100%;
          background: transparent;

          .vrtx-frontpage-box-picture {
            width: 100%;
            height: 100%;
            max-width: none;

            img {
              display: block;
            }
          }
        }
      }

      &.img-right:nth-of-type(2) {
        margin-top: -80px;
      }

      .vrtx-frontpage-box {
        h2 {
          padding-top: 60px;
          font-size: 40px;
          font-size: 4rem;
          line-height: 1.5;
          margin-bottom: -20px;
          padding-bottom: 0;
          color: @color-dark-text;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
        }

        .vrtx-box-content {
          padding-top: 60px;
          padding-bottom: 60px;

          h2 {
            padding-top: 0;
            margin-bottom: 0;
          }

          p {
            font-weight: 300;
          }

          a {
            font-weight: 400;
          }

          .item-title,
          .vrtx-event-component-title.summary {
            font-weight: 500;

            &:focus,
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .vrtx-more {
          a {
            color: @color-dark;
            font-size: 20px;
            font-size: 2rem;
          }

          a:before {
            display: none;
          }
        }

        &.list {
          li {
            font-family: "Hind", sans-serif !important;
            font-size: 18px;
            font-weight: 300;
          }
        }

        &.support {
          .vrtx-box-content {
            background-color: transparent;
            overflow: hidden;
          }
        }

        &.banner-blockquote {
          .vrtx-box-content blockquote {
            margin-left: 0 !important;
          }
        }

        &.links {
          a.link {
            text-decoration: none;
            border: 4px solid @color-dark-text;
            padding: 20px 20px 20px 86px;
            width: 100%;
            display: block;
            color: @color-dark-text;
            position: relative;
            font-size: 16px;

            &:focus,
            &:hover {
              text-decoration: underline;
            }

            &::before {
              content: "";
              float: left;
              position: absolute;
              left: 0;
              top: 0;
              width: 66px;
              height: 100%;
              background: @color-dark-text;
            }

            &.facebook::before {
              background: @color-dark-text url("../images/facebook-icon-white.svg") no-repeat center center;
              background-size: 50%;
            }

            &.twitter::before {
              background: @color-dark-text url("../images/twitter-x.svg") no-repeat center center;
              background-size: 100%;
            }

            &.linkedin::before {
              background: @color-dark-text url("../images/linkedin.svg") no-repeat center center;
              background-size: 45%;
            }

            &.newsletter::before {
              background: @color-dark-text url("../images/email.svg") no-repeat center center;
              background-size: 50%;
            }
            &.youtube::before {
              background: @color-dark-text url("../images/youtube-icon-white.svg") no-repeat 45% center;
              background-size: 70%;
            }
          }

          /* current version: full-width og fire i bredden: */
          &.flex {
            padding: 0;
            .vrtx-box-content {
              width: 100%;
              padding-bottom: 60px;
              p {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
              }
              a.link {
                flex: 0 22.5%;
              }
            }
          }
        }

        &.events-feed {
          h2 {
            padding-top: 80px;
          }
          padding-bottom: 80px;
          .vrtx-more {
            margin-top: -15px;
            margin-bottom: 80px;
          }
        }

        &.news-feed {
          h2 {
            padding-top: 80px;
          }
          ul:after {
            display: none;
          }
          .items li .content {
            background: @color-bg-light;
            min-height: 182px;
            max-height: 182px;
            a.item-title {
              text-decoration: none;
              max-height: 110px;
              min-height: 110px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .vrtx-more {
            margin-top: -15px;
          }
        }
      }

      /* Banner */
      &.grid-banner {
        position: relative;

        .row {
          padding: 0;
        }

        .banner {
          overflow: hidden;
          margin: 80px 0;

          .vrtx-frontpage-box-picture {
            width: 30%;
            max-width: 30%;
            height: 100%;
            overflow: hidden;
            background-size: cover !important;
            display: block;
            height: 239px;
            float: left !important;

            img {
              display: none;
            }
          }

          .vrtx-box-content {
            width: 70%;
            float: right;
            padding: 40px 40px 80px;
            background: @color-grey-mobile;

            h2,
            h2 a {
              font-weight: 400;
              font-family: "Hind", sans-serif;
              font-size: 3.7rem;
              font-size: 37px;
              line-height: 1.5;
            }

            a {
              color: @color-dark;
              font-size: 18px;
              font-size: 1.8rem;
              line-height: 28px;
            }
          }

          &.full-width-picture {
            // add class bg img full width
            height: 250px;
            max-height: 250px;
            overflow: hidden;
            background-size: cover !important;
            margin: 0;

            .vrtx-box-content {
              display: none;
            }
          }
        }
      }

      /* Project support */
      .vrtx-frontpage-box.half-width-picture.support {
        opacity: 0;
      }

      &.img-right {
        margin-top: -80px;
        background-position-x: right !important;
        background-color: @color-bg2;
        position: relative;
        margin-bottom: 80px;

        .half-width-picture.support {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          height: 100% !important;
          background-size: cover !important;
          /*-webkit-animation: fadein 0.5s;
          animation: fadein 0.5s;*/
          opacity: 1;
          .vrtx-frontpage-box-picture {
            display: none;
          }
        }
      }
      div.vrtx-more a {
        text-decoration: none;
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
      /* news feed color */
      &.row-all-colored, .row-all-colored.row-first-colored .vrtx-frontpage-box.grey-box {
        background-color: @color-news-feed-bg;
        .vrtx-frontpage-box {
          background-color: @color-news-feed-bg;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &.research-school {
      background: @color-research-school-bg;
      height: 250px !important;
      margin: 60px 0;
      padding-right: 0;

      h2 {
        width: 70% !important;
        padding: 40px 40px 30px;
        display: inline-block;
        width: auto;
        font-weight: 400;
        font-family: "Hind", sans-serif;
        font-size: 3.5rem;
        font-size: 35px;
        line-height: 1.5;

        a {
          color: @color-dark;
        }
      }

      .vrtx-box-content {
        padding: 0 40px;
        width: 70%;
      }

      .vrtx-frontpage-box-picture {
        float: right;
        width: 30%;
        padding: 0;
        height: 250px;
        position: absolute;
        right: 0;
        top: 0;

        a {
          height: 100%;
          overflow: hidden;

          img {
            height: 100%;
            width: auto;
            max-width: none;
            float: right;
          }
        }
      }
    }
  }
  /* end index  (frontpage) */
 

  // person-listing, employees, task-force
  &.not-for-ansatte #main .grid-container .column-wrapper.triple {
    display: flex;
    align-items: stretch;
    .vrtx-frontpage-box.employee {
      height: auto !important;
      &.grey-box, &.neg-margin-top, &.padding-top {
        .vrtx-box-content {
          padding: 30px 5px 40px 0;
        }
        .vrtx-frontpage-box-picture {
         padding-top: 30px;
        }
      }
    }
  }

  /* sub pages with color top */
  &.not-for-ansatte #main #total-main {
    .grid-container:first-of-type {
      opacity: 0;
    }

    .grid-container.full-width-picture-bg,
    .grid-container.row-all-colored:first-of-type {
      height: auto;
      min-height: 300px;
      padding-top: 100px;
      .row {
        padding: 0;
      }

      .vrtx-frontpage-box {
        .vrtx-frontpage-box-picture {
          opacity: 0;

          img {
            opacity: 0;
          }
        }

        h2 {
          font-size: 40px;
          font-size: 4rem;
          line-height: 1.3;
          padding-top: 60px;
        }

        a {
          color: @color-dark;
        }

        .half-width-picture {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          height: 100% !important;
          background-size: cover !important;

          /*-webkit-animation: fadein 0.5s;
        animation: fadein 0.5s;*/
          .vrtx-frontpage-box-picture {
            display: none;
          }
        }
      }

      .vrtx-frontpage-box-picture {
        height: auto;
      }

      .vrtx-box-content {
        left: 0;
        bottom: 0;
        position: relative;
        //padding-bottom: 80px;

        p {
          font-size: 2rem;
          line-height: 1.6;
        }
      }
    }

    /* researh school */
    .vrtx-frontpage-box.third-box-left {
      border-right: none;
    }

    .vrtx-frontpage-box.third-box-right {
      border-left: none;
    }

    /* support page */
    .grid-container.full-width-picture-bg:nth-of-type(2) {
      margin-top: -60px;
    }

    .grid-headline-only {
      margin-bottom: 0;
      h2 {
        padding: 0;
      }
    }

    .row-all-colored.grid-headline-only {
      margin-bottom: -60px;
      h2 {
        padding: 60px 0 0;
      }
    }

    .vrtx-frontpage-box.vrtx-more {
      .vrtx-more {
        padding-top: 40px;
        a {
          text-decoration: none;
          &:focus,
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .full-width-picture.content-over-image-center {
      .vrtx-box-content {
        padding: 40px;
        margin-bottom: 100px;
        background: rgba(255, 255, 255, 0.85);

        p {
          line-height: 1.6;
          display: inline;
          font-size: 20px;
        }
      }
    }

    /* Project support */
    .grid-container.img-right:nth-of-type(2) {
      margin-top: -80px;
    }

    .grid-container.img-right {
      margin-top: 0;
      margin-bottom: 0;
      background-position-x: right !important;
      position: relative;

      .half-box-left {
        padding: 80px 60px 60px 0;
        height: auto !important;
        .vrtx-more {
          padding-bottom: 0;
        }
      }

      .half-width-picture {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        height: 100% !important;
        background-size: cover !important;
        .vrtx-frontpage-box-picture {
          display: none;
        }
      }
    }

    .grid-container.img-left {
      margin-top: 0;
      margin-bottom: 0;
      background-position-x: left !important;
      position: relative;

      .row {
        &::before {
          display: none;
        }
      }

      .half-width-picture {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        height: 100% !important;
        background-size: cover !important;

        .vrtx-frontpage-box-picture {
          display: none;
        }
      }

      .half-box-right {
        padding: 80px 0 60px 60px;
        height: auto !important;
        float: right;
        .vrtx-more {
          padding-bottom: 0;
        }
      }
    }

    /* add white newsfeed on grid-container row-all-colored*/
    .grid-container.row-all-colored {
      .vrtx-frontpage-box.grey-box.news-feed {
        .vrtx-box-content {
          .items li {
            .content {
              background: @color-light;
            }
          }
        }
      }
    }
  }
  //Saving editormode
  &.not-for-ansatte #main #total-main {
    .vrtx-edit-grid {
      .grid-container:first-of-type {
        opacity: 1;
      }
    }
  }

  /* Special styling research school frontpage */
  &.not-for-ansatte #main.research-school-frontpage #total-main {
    .grid-container.row-all-colored.row-last-colored {
      background-color: #dbd0e8;
      .vrtx-frontpage-box.grey-box {
        background-color: transparent;
      }
    }
  }

  &.not-for-ansatte #main #vrtx-main-content .grid-container .vrtx-frontpage-box.logos-bottom {
     height: 0px;
     margin-bottom: 20px;
  }

  &.not-for-ansatte #main #total-main #vrtx-main-content .grid-container .vrtx-frontpage-box {
    &.list.support {
      margin: 80px 0 60px;
      padding: 0 60px 0 0;
      height: auto !important;
      h2 {
        padding-top: 0;
        padding-bottom: 0;
      }
      .vrtx-box-content {
        padding-bottom: 20px;
        margin-left: 0;
        p {
          font-weight: 300;
        }
        a {
          color: @color-dark;
          font-size: 18px;
          font-size: 1.8rem;
          padding-top: 30px;
        }
        ul {
          margin-top: 30px;
          li {
            margin: 0 0 0 21px;
            padding-left: 30px;
            margin-bottom: 20px;
            font-weight: 400;
            &:before {
              content: "";
              margin-left: -2.5ex;
              position: absolute;
              top: 50%;
              margin-top: -13px;
              left: 0;
              width: 22px;
              border: 2px solid;
              height: 22px;
              overflow: hidden;
              font-size: 0.8em;
              color: #221e20;
              p {
                font-weight: 400;
              }
            }
            &.responsible::before {
              background: url("/images/002-checked.svg") no-repeat center center;
              background-size: 58%;
            }
            &.data-management::before {
              background: url("/images/005-web-development.svg") no-repeat center center;
              background-size: 58%;
            }
            &.innovation::before {
              background: url("/images/010-rocket-1.svg") no-repeat center center;
              background-size: 58%;
            }
            &.collaborate::before {
              background: url("/images/team-leader.svg") no-repeat center center;
              background-size: 58%;
            }
            &.career::before {
              background: url("/images/career.svg") no-repeat center center;
              background-size: 58%;
            }
            p {
              margin: 0;
              a {
                display: block;
                padding-top: 30px;
              }
            }
          }
        }
      }
    }
  }

  #main {
    .navigation-links {
      ul {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        gap: 30px;
      }
      li {
        display: grid;
        width: 100%;
        padding: 0;
        margin: 0;
      }
      a {
        position: relative;
        background-color: #c9efef;
        padding: 15px 60px 15px 15px;
        text-decoration: none;
        color: @color-dark;
        transition: all 0.3s;
        &:hover, &:focus {
          background-color:#258181;
          color: @color-light;
        }
        &:after {
          background: none;
          content: "→";
          font-size: 3rem;
          top: .8rem;
        }
      }
      p {
        margin: 0;
      }
      strong {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
only screen and (max-width: 19cm) and (orientation: landscape) {

    body:not(.vrtx-editor-frontpage)#vrtx-frontpage.vrtx-frontpage-two.not-for-ansatte {
    #head-wrapper {
      background: @color-light;
      height: 147px !important;
      position: relative;
      &.frontpage {
        background: @color-light;
      }
    }
     
    &.the-frontpage #main, #main.frontpage {
      #total-main #vrtx-main-content .grid-container {
        &.full-width-picture-bg:nth-of-type(1) {
          min-height: 550px;
          .frontpage-first-box.font-light .vrtx-box-content {
            margin-top: 0;
            padding: 20px;
            h2 {
              padding: 10px 0 0;
              font-size: 2.4rem;
              line-height: 1.7;
              text-shadow: none;
              color: @color-dark;
              text-rendering: geometricPrecision;
            }
            p:not(.transparent) {
              font-size: 1.6rem;
              color: @color-dark;
              background: transparent;
              line-height: 1.6;
              display: inline-block;
            }
          }
        }
      }
    }

    #main #total-main {
      .grid-container.full-width-picture-bg,
      .grid-container.row-all-colored:first-of-type {
        .vrtx-frontpage-box {
          h2 {
            font-size: 3rem;
            padding-top: 40px;
          }
          .vrtx-frontpage-box-picture {
            display: none;
          }
        }
      }
    }

    &.vrtx-frontpage-full-width.total-main {
      #main {
        #total-main .grid-container {
          margin-bottom: 0;
          &:first-of-type {
            margin-bottom: 0;
          }
          &.img-right:nth-of-type(2) {
            margin-top: 0;
          }
          .row {
            padding-bottom: 0;
          }
        }
        &.project {
          #total-main .grid-container {
            margin-bottom: 30px;
            &.full-width-picture-bg {
              .full-width-picture.project {
                h2 {
                  font-size: 4rem;
                }
                p {
                  font-size: 2rem;
                }
              }
              .vrtx-box-content {
                padding-bottom: 20px;
              }
            }
          }
        }
        
        &.research {
          #total-main .grid-container {
            &.filter {
              margin-top: 0;

              .vrtx-frontpage-box.filter-new {
                .vrtx-box-content {
                  padding: 15px 0 30px;
                }
              }
            }
            &.full-width-picture-bg {
              margin-bottom: 0;
              padding-bottom: 0;
              .vrtx-box-content {
                padding-bottom: 70px;
                p {
                  font-size: 1.6rem;
                }
              }
            }
          }
        }

        &.conference-frontpage-new #total-main .grid-container:last-of-type {
          margin-bottom: 0;
        }
        &.project #total-main .grid-container {
          &.full-width-picture-bg {
            padding-bottom: 0;
            .row .full-width-picture .vrtx-box-content p {
              font-weight: 600;
            }
          }
          &:last-of-type {
            margin-bottom: 80px;
            .vrtx-frontpage-box.news-feed {
              padding-bottom: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    #main {   
      .vrtx-back {
        margin: -140px 0 10px 0;
        padding-left: 15px;
      }
      h1 {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 0;
      }
      #vrtx-frontpage-introduction {
        margin-bottom: 0;
        padding: 20px 20px 0;
      }
   
      #total-main #vrtx-main-content .grid-container {
        &.responsive-margin-top {
          margin-top: 50px;
        }
        &.responsive-no-margin-top {
          margin-top: 0;
        }
        &:first-of-type {
          margin-bottom: 0px;
        }
        &.img-right {
          padding-bottom: 0;
        }
        .row {
          width: 100%;
          .vrtx-frontpage-box {
            &.list.support {
              margin: 0;
              padding: 20px;
              h2 {
                font-size: 2.4rem;
              }
              .vrtx-box-content {
                padding-top: 20px;
                padding-bottom: 0px;
              }
            }
            .vrtx-more a {
              font-size: 1.8rem;
            }
            &.research-school {
              padding-top: 20px;
              h2 {
                padding: 0;
              }
            }
          }
        }
      }
      &.conference {
        #total-main .grid-container.full-width-picture-bg {
          .vrtx-frontpage-box h2 {
            font-size: 3rem;
          }
        }
        &.conference-frontpage-new, &.conference-frontpage{
          margin-top: 40px;
          #vrtx-main-content .grid-container {
            .vrtx-frontpage-box h2 {
              &.accordion {
                padding-top: 0;
              }
            }
          }
        }
      }
      
      &.project { 
        margin-top: 0;
        #total-main {
          margin-top: 0;
          .grid-container.full-width-picture-bg {
            -webkit-animation: fadein 0s;
            animation: fadein 0s;
          }
        }
      }
      
      &.conference.conference-frontpage-new, &.conference.conference-frontpage, &.project {
        #total-main .grid-container {
          .vrtx-frontpage-box.big-padding-top .vrtx-box-content {
            padding-top: 0px;
          }
          &:not(.row-last-colored):not(.row-first-colored):not(.no-margin-top):not(.full-width-picture-bg) {
            padding-top: 0;
          }
        }
      }
      &.research-school {
        #total-main .grid-container {
          margin-bottom: 0;
        }
      }
      &.frontpage {
        margin: 230px auto 0 auto;
        #total-main #vrtx-main-content .grid-container {
          .vrtx-frontpage-box {
            &.news-feed,
            &.events-feed {
              h2 {
                font-size: 2.4rem;
                padding-top: 20px;
              }
            }
            &.links.flex .vrtx-box-content {
              padding-bottom: 10px;
              padding-top: 10px;
              a.link,
              p {
                flex: 100%;
              }
            }
          }
        }
      }
    }
    
    #main #vrtx-main-content .grid-container.full-width-picture-bg:nth-of-type(1) {
      opacity: 1 !important;
      min-height: auto;
      padding-top: 0;
      -webkit-animation: none;
      animation: none;
      margin-bottom: 0;

      .row {
        .font-dark, .font-light {
          padding: 0 20px;
          .vrtx-frontpage-box-picture {
            opacity: 1;
            display: block;

            img {
              opacity: 1;
              display: block;
              -webkit-animation: fadein 0.5s;
              animation: fadein 0.5s;
            }
          }
        }
      }
    
      .row .vrtx-frontpage-box.frontpage-first-box {
        padding-left: 0;
        padding-right: 0;

        .vrtx-frontpage-box-picture {
          opacity: 1;
          display: none;

          img {
            opacity: 1;
            display: none;
          }
        }

        h2 {
          padding-top: 40px;
          font-size: 30px;
          font-size: 3rem;
          line-height: 1.5;
        }

        .vrtx-box-content {
          padding: 0 20px 70px;
          padding: 0 20px 20px;

          p {
            font-size: 1.6rem;
            line-height: 1.6;
          }
        }
      }
    }

    #main #vrtx-main-content .grid-container {
      &:first-of-type:last-of-type .row .vrtx-frontpage-box{
        padding: 0 20px;
      }
      /* banner */
      &.full-width-picture-bg.grid-banner {
        margin: 0 !important;
        width: 100%;
        padding: 0;
        display: inline;
      }

      .vrtx-frontpage-box {
        h2 {
          padding-top: 0;
          font-size: 24px;
          font-size: 2.4rem;
          line-height: 34px;
          line-height: 3.4rem;
        }
        &.row-color-blue,
        &.row-color-lightblue {
          h2 {
            padding-top: 20px;
          }
        }

        .vrtx-more a {
          font-size: 16px;
          font-size: 1.6rem;
          line-height: 2;
        }

        &.banner {
          margin: 40px 0 0;

          .vrtx-box-content,
          .vrtx-frontpage-box-picture {
            width: 100%;
            max-width: 100%;
          }

          .vrtx-box-content {
            padding: 20px;

            h2 a {
              font-size: 24px;
              font-size: 2.4rem;
              line-height: 34px;
              line-height: 3.4rem;
            }

            a {
              color: @color-dark;
              font-size: 16px;
              font-size: 1.6rem;
              line-height: 26px;
            }
          }

          &.full-width-picture {
            padding: 0;

            .vrtx-frontpage-box-picture {
              opacity: 1;
            }
          }
        }

        &.research-school {
          .vrtx-frontpage-box-picture {
            display: none;
          }
          .vrtx-box-content {
            padding: 0;
            width: 100%;
          }
        }
        &.links {
          margin: 0;
          padding-top: 38px;
          a.link {
            padding: 15px 20px 20px 86px;
          }
          &.flex {
            padding: 0;
            .vrtx-box-content {
              width: 100%;
              margin: 0;
              padding-top: 40px;
              padding-bottom: 40px;
              p {
                display: block;
                padding: 0 40px;
              }
              a.link {
                flex: none;
                display: block;
                width: 100%;
                margin-bottom: 25px;
                padding: 15px 15px 15px 80px;
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        &.list.support .vrtx-box-content ul li:before {
          content: "";
          margin-left: -2.5ex;
          position: absolute;
          top: 50%;
          margin-top: -19.5px;
          font-size: 0.7em;
        }

        &.news-feed {
          padding: 20px 20px 10px;
          .items li .content {
            margin-top: -10px;
            .item-title {
              padding-top: 10px;
            }
          }
        }

        &.events-feed {
          padding: 20px 20px 10px;
          .vrtx-event-component .vrtx-event-component-item .content::after {
            content: "";
            display: none;
          }
          .vrtx-more {
            margin-bottom: 0;
          }
        }

        &.banner-blockquote {
          .vrtx-box-content {
            padding: 20px 0 0;
          }
        }
      }
       .column-wrapper.triple {
        flex-direction: column;
        .vrtx-frontpage-box.employee {
          height: auto;
          display: flex;
          justify-content: space-between;
          .vrtx-frontpage-box-picture, .vrtx-box-content {
            left: 0;
            margin: 0;
            padding: 0;
            flex: 1;
            position: relative;
            img {
              max-width: 140px;
            }
          }
          &.grey-box {
            .vrtx-frontpage-box-picture, .vrtx-box-content {
              padding: 30px 0;
            }
          }
          &:first-of-type {
            margin-top: 40px;
          }
          &.third-box-right {
            margin: 0 0 20px;
            padding: 0 20px;
            flex: 1;
          }
        }
      }
    }

    #main #vrtx-main-content .grid-container {
      &.img-right {
        padding-bottom: 40px;
        margin-bottom: 0;
        .list.support {
          z-index: 100;
          width: 100%;
          padding: 0 20px;
          margin: 0;
          .vrtx-box-content {
            padding-top: 40px;
          }
        }
        .half-width-picture.support {
          position: relative;
          width: 100%;
        }
      }

      .vrtx-frontpage-box {
        h2 {
          padding-top: 30px;
          font-size: 24px;
          line-height: 1.7;
          &.accordion {
            padding-top: 20px;
          }
        }
        div.vrtx-more a {
          font-size: 16px;
          font-size: 1.6rem;
        }
      }
    }

    #main #total-main .grid-container {
      &.img-left, &.img-right {
        .vrtx-frontpage-box {
          padding: 0 20px 20px;
          margin-bottom: 30px;
          &.half-width-picture {
            position: relative;
            display: none;
          }
          &.grey-mobile {
            background: @color-grey-mobile;
            padding: 20px 20px 40px;
            h2 {
              padding-top: 0;
            }
          }
          &.list {
            .vrtx-box-content ul li {
              font-size: 16px;
              font-size: 1.6rem;
              line-height: 1.5;
              padding-bottom: 10px;
              &:before {
                top: 5px;
                font-size: 0.6em;
              }
            }
          }
          &.white-mobile {
            background-color: @color-light;
          }
        }
      }
    
      .vrtx-frontpage-box.white-mobile {
        background-color: @color-light;
        padding: 40px 20px;
      }

      .vrtx-frontpage-box.grey-mobile {
        background-color: @color-grey-mobile;
        padding: 20px 20px;
      }

      .vrtx-frontpage-box.last-box {
        margin-bottom: 0 !important;
      }

      .vrtx-frontpage-box.xtra-padding {
        padding-bottom: 40px;
      }

      .vrtx-frontpage-box {
        padding: 0 20px;
      }

      /* Research school */
      .vrtx-frontpage-box.link-box {
        .vrtx-box-content {
          display: block;
          width: 100%;
          h2 {
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            a {
              height: auto;
              margin-right: 0;
              margin-left: 0;
            }
          }
        }
      }

      .vrtx-frontpage-box.dlnrs-news-feed {
        h2 {
          padding-top: 20px;
        }
      }
    }

    /* Special styling research school frontpage */
    #main.research-school-frontpage #total-main {
      .grid-container.full-width-picture-bg:nth-of-type(1) {
        background-color: @color-research-school !important;
      }  
    }
    .grid-container .vrtx-frontpage-box.neg-margin-top {
      margin-top: 0;
    }
    #main {
      .navigation-links {
        ul {
          margin-top: 40px;
          grid-template-columns: 1fr;
        }
      }
    }

    /* frontpage norwegian*/
    &.no {
      &.the-frontpage #main,
      #main.frontpage {
        #total-main #vrtx-main-content .grid-container {
          .vrtx-frontpage-box.links.flex .vrtx-box-content p {
            margin-top: 60px;
          }
          .vrtx-frontpage-box.logos-bottom-new .vrtx-box-content {
            margin-top: 60px;
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}            