/* Conference frontpage */
.not-for-ansatte #main.conference-frontpage #total-main {
  .grid-container {
    background-color: #e8f6fc;
    margin-bottom: 0;
    padding: 60px 0;

    &:nth-of-type(2) {
      padding-top: 60px;
    }

    &.row-all-no-margin-bottom {
      padding-bottom: 0;
    }

    &.white-bg {
      background: white;
    }

    &.light-blue-bg {
      background-color: #f3fbff;
    }

    &.no-margin-top {
      padding-top: 0;
    }
  }

  .grid-container:last-of-type {
    padding-bottom: 60px;
  }
}

.not-for-ansatte #main.conference-frontpage #total-main,
.not-for-ansatte #main.conference-frontpage-new #total-main {
  .grid-container .vrtx-frontpage-box {
    h2.accordion {
      border: none !important;
      padding-top: 0;
      padding-bottom: 0;
    }

    h3.accordion {
      border: none !important;
    }

    h2,
    h2.ui-accordion-header {
      font-size: 24px;
      line-height: 34px;
    }
  }

  .grid-container.full-width-picture-bg {
    margin-top: 60px;
    margin-bottom: 0;

    .vrtx-frontpage-box.full-width-picture {
      .vrtx-box-content {
        h2 {
          padding-top: 30px;
          text-shadow: 0 1px 2px rgba(94, 94, 94, 0.5);
          text-rendering: geometricPrecision;
          font-size: 6rem;
          line-height: 1.2;
          max-width: 80%;
          margin: 0 auto;
        }

        p {
          font-size: 2rem;
          line-height: 1.5;
        }
      }

      &.center-content {
        .vrtx-box-content {
          text-align: center;

          a.button,
          button {
            float: none;
            width: auto;
            display: inline-block;
          }
        }
      }

      &.font-dark {
        .vrtx-box-content {
          h2,
          p {
            color: #000;
          }

          .button,
          button {
            display: block;
            float: left;
            border: 3px solid #000;

            &:focus,
            &:hover {
              background: rgba(255, 255, 255, 0.4);
              color: #000;
            }
          }
        }

        &.txt-bg p {
          line-height: 2;
          display: inline;
          background: rgba(255, 255, 255, 0.4);
        }
      }

      &.font-light {
        .vrtx-box-content {
          a,
          button a.button,
          h2,
          p {
            color: #ffffff;
          }

          p {
            font-size: 2rem;
            line-height: 1.5;
            float: none;
          }

          .button,
          button {
            display: block;
            float: left;
            border: 3px solid #ffffff;

            &:focus,
            &:hover {
              background: rgba(0, 0, 0, 0.4);
              color: #ffffff;
            }
          }
        }

        &.txt-bg p {
          display: inline;
          line-height: 2;
          background: rgba(0, 0, 0, 0.4);
        }

        &.txt-bg a.button,
        &.txt-bg button {
          background: rgba(0, 0, 0, 0.4);
          display: block;
          width: auto;
          float: none;
          width: 170px;
          text-align: center;
          margin: 20px auto;
          padding: 7px 20px;

          &:focus,
          &:hover {
            background: rgba(0, 0, 0, 0.8);
            color: #ffffff;
          }
        }
      }
    }
  }

  .grid-container .vrtx-frontpage-box.large-video {
    .vrtx-box-content {
      div:first-of-type {
        margin-top: -2px;
      }
      overflow: hidden;
      margin: 0 auto;
      width: 638px;
    }

    .vjs-poster,
    .vrtx-media-player {
      background-color: transparent;
    }
  }

  .grid-container .vrtx-frontpage-box.small-video {
    &.third-box-left {
      padding-right: 10px;
    }

    &.third-box-right {
      padding-left: 10px;
    }
    padding: 0;
    margin-top: -40px;

    .vrtx-box-content {
      margin: 0 auto;

      .vrtx-media-player {
        position: relative;
        margin-bottom: 0;
      }

      .vjs-poster,
      .vrtx-media-player {
        background-color: transparent;
      }
    }
  }

  .grid-container.white-bg {
    .vrtx-frontpage-box.small-video {
      .vrtx-box-content {
        .vrtx-media-player::before {
          outline: 5px solid #ffffff;
        }
      }
    }
  }

  .grid-container .vrtx-frontpage-box.neg-margin-top {
    margin-top: -30px;
  }

  .grid-container .vrtx-frontpage-box.xl-txt {
    .vrtx-box-content {
      a,
      li,
      li a,
      li p,
      p {
        font-size: 2rem;
        line-height: 3rem;
        font-weight: 500;
        padding: 15px 0;
      }
    }
  }

  .grid-container .small-width .vrtx-box-content {
    width: 640px;
    margin: 0 auto;
    padding: 0 10px;
  }

  .grid-container .large-list {
    .vrtx-box-content {
      li,
      li a,
      li p {
        font-size: 1.8rem;
        line-height: 2.8rem;
        font-weight: 300;
      }

      li {
        margin-top: 10px;
      }

      li a {
        font-weight: 400 !important;
      }
    }
  }

  .grid-container .vrtx-frontpage-box.center-content {
    .vrtx-box-content {
      text-align: center;
    }
  }

  .grid-container .vrtx-frontpage-box {
    min-height: fit-content;
    min-height: -moz-fit-content;
    height: fit-content;
    height: -moz-fit-content;
  }

  .grid-container .vrtx-frontpage-box.time {
    background: #c0e9f1;
    background: #cfeffc;
    display: table;
    padding: 0;

    .vrtx-box-content {
      text-align: center;
      display: table-cell;
      vertical-align: middle;

      h3 {
        padding: 15px 0;
      }
    }
  }

  .grid-container .vrtx-frontpage-box.break {
    .vrtx-box-content {
      p {
        padding: 15px 0;
        -webkit-text-stroke: thin;
      }
    }
  }

  .grid-container .vrtx-frontpage-box.vertical-middle {
    display: table;

    .vrtx-box-content {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .grid-container .vrtx-frontpage-box.blue-box .vrtx-box-content {
    padding: 40px;
    background-color: #c0e9f1;
    background-color: #cfeffc;
  }

  .grid-container .vrtx-frontpage-box.speaker-list {
    ul li {
      min-height: 165px;
      width: 100%;
      margin-left: 0 !important;
      display: block;
      border-bottom: 1px solid #eaeaea;
      padding: 30px 0;
      margin: 0;

      &::before {
        display: none;
      }

      h3 {
        margin-top: 0;
        display: inline-block;
        padding-left: 40px;
        display: block;
      }

      p {
        margin-left: 100px;
        padding-left: 20px;
      }

      .image-left,
      image,
      p.image {
        position: relative;
        float: left;
        left: 0;
        top: 0;
        margin-left: 0;
        padding-left: 0;
        margin-top: 0;
      }

      p.image img {
        margin: 0 20px 0 0;
      }
    }
  }

  #vrtx-additional-content {
    display: none !important;
  }
}

#main.conference-frontpage-new #total-main {
  .grid-container {
    background: transparent;

    &.grid-color-blue {
      background: #e8f6fc;
    }

    &.grid-color-lightblue {
      background: #f3fbff;
    }

    &.row-all-no-margin-bottom {
      margin-bottom: 0;
    }

    .vrtx-frontpage-box {
      height: auto;
      padding: 40px 0;

      &.programme-container {
        margin-bottom: 0;
        min-height: fit-content;
        min-height: -moz-fit-content;
        height: fit-content !important;
        height: -moz-fit-content !important;

        .ui-accordion.ui-widget .ui-accordion-content {
          padding: 0;
        }

        .grid-container:first-of-type {
          padding-top: 20px;
        }

        .grid-container:not(:first-of-type) {
          .vrtx-frontpage-box {
            margin-top: -30px;
          }
        }

        .vrtx-frontpage-box {
          height: fit-content !important;
          height: -moz-fit-content !important;

          .vrtx-box-content {
            display: block !important;
            height: fit-content;
            height: -moz-fit-content;
          }
        }

        .vrtx-frontpage-box:not(.third-box-left):not(.third-box-right):not(.third-box-left) {
          min-height: fit-content;
          min-height: -moz-fit-content;
          height: fit-content !important;
          height: -moz-fit-content !important;
        }
      }

      &.programme-table {
        .ui-accordion.ui-widget .ui-accordion-content > *:first-child {
          margin-top: 40px;
        }
        /* setup 2022 */
        .full-width-box,
        .wrapper .column {
          h4 {
            font-size: 2rem;
          }
        }

        .wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-columns: repeat(2, 33.333% 66.666%);
          margin-bottom: 60px;
          margin-top: 20px;

          .column {
            display: inline-grid;
            display: inline-grid;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &.time {
              justify-content: center;
              background: #cfeffc;
              padding: 20px;
              font-size: 23px;
            }

            &.info {
              padding-left: 40px;

              :first-child {
                margin-top: 0;
              }
            }
          }
        }

        div.full-width-box {
          padding: 50px;
          background: #cfeffc;
          margin-bottom: 60px;
          margin-top: 20px;
          font-size: 2rem;
          line-height: 3rem;

          * {
            padding: 0;
            margin: 0 auto;
          }

          &.center-content {
            text-align: center;
          }
        }

        table,
        td {
          border: none;
        }

        table {
          margin: 60px 0;

          td:first-of-type {
            background: #cfeffc;
            width: 33.333%;
            vertical-align: middle;
            padding: 15px;
            text-align: center;
            font-size: 2rem;
            line-height: 3rem;
            font-weight: 400;

            h3,
            p {
              font-size: 2rem;
              line-height: 3rem;
              font-weight: 400;
            }

            h3 {
              display: flex;
              height: 100%;
              justify-content: center;
              align-items: center;
              font-size: 2rem;
              line-height: 3rem;
              font-weight: 500;
            }

            &:last-of-type {
              padding: 50px;
            }
          }

          td:last-of-type {
            padding: 0 0 0 40px;
          }
        }

        &.speaker-list {
          ul li {
            min-height: 165px;
            width: 100%;
            margin-left: 0 !important;
            display: block;
            border-bottom: 1px solid #eaeaea;
            padding: 30px 0;
            margin: 0;

            &::before {
              display: none;
            }

            h4 {
              margin-top: 0;
              display: inline-block;
              padding-left: 40px;
              display: block;
            }

            p {
              margin-left: 100px;
              padding-left: 20px;
            }

            .image-left,
            image,
            p.image {
              position: relative;
              float: left;
              left: 0;
              top: 0;
              margin-left: 0;
              padding-left: 0;
              margin-top: 0;
            }

            p.image img {
              margin: 0 20px 0 0;
            }
          }
        }
      }
    }
  }
}
/*@media screen and (max-width: 718px) {*/
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte #main.conference-frontpage-new #total-main {
    .grid-container .small-width .vrtx-box-content {
      width: 100%;
      padding: 0 15px;
    }

    .grid-container.full-width-picture-bg:nth-of-type(1) {
      margin-top: 0;
      opacity: 1 !important;
      min-height: auto;
      padding-top: 0;
      -webkit-animation: none;
      animation: none;

      .vrtx-frontpage-box.full-width-picture {
        padding-left: 0;
        padding-right: 0;

        .vrtx-frontpage-box-picture {
          opacity: 1;
          display: block !important;

          img {
            opacity: 1;
            display: block !important;
          }
        }

        h2 {
          padding-top: 40px;
          font-size: 30px;
          font-size: 3rem;
          line-height: 1.5;
          color: #000;
        }

        .vrtx-box-content {
          padding: 0 20px 70px;

          h2 {
            padding-top: 40px;
            font-size: 30px;
            font-size: 3rem;
            line-height: 1.5;
            color: #000 !important;
            text-shadow: none !important;
          }

          p {
            color: #000 !important;
            background: transparent !important;
          }
        }
      }
    }

    #vrtx-content .grid-container .vrtx-frontpage-box {
      h2,
      h2.ui-accordion-header {
        font-size: 24px;
        line-height: 34px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      &.large-video {
        .vrtx-box-content {
          width: 100%;
        }
      }

      &.programme-table {
        /* new */
        .wrapper {
          display: block;
          margin-top: 0;
          margin-bottom: 0;

          .column {
            margin: 20px 0;

            &.info {
              padding-left: 0;
            }
          }
        }

        .full-width-box {
          margin: 20px 0;
          font-size: 18px;
        }

        table {
          margin: 20px 0;

          tbody,
          td,
          td:first-of-type,
          thead,
          tr {
            width: 100%;
            display: block;
          }

          td {
            margin-bottom: 20px;
            padding: 20px;
          }

          td:first-of-type {
            &:last-of-type {
              padding: 20px;
            }
          }

          td:last-of-type {
            padding: 0;
          }
        }

        .table-wrapper-responsive:last-of-type {
          table {
            margin-bottom: 40px;
          }
        }
      }
    }
  }

  .not-for-ansatte #main.conference-frontpage #total-main {
    .grid-container.full-width-picture-bg:nth-of-type(1) {
      margin-top: 0;
      opacity: 1 !important;
      min-height: auto;
      padding-top: 0;
      -webkit-animation: none;
      animation: none;

      .vrtx-frontpage-box.full-width-picture {
        padding-left: 0;
        padding-right: 0;

        .vrtx-frontpage-box-picture {
          opacity: 1;
          display: block !important;

          img {
            opacity: 1;
            display: block !important;
          }
        }

        h2 {
          padding-top: 40px;
          font-size: 30px;
          font-size: 3rem;
          line-height: 1.5;
          color: #000;
        }

        .vrtx-box-content {
          padding: 0 20px 70px;

          h2 {
            padding-top: 40px;
            font-size: 30px;
            font-size: 3rem;
            line-height: 1.5;
            color: #000 !important;
            text-shadow: none !important;
          }

          p {
            color: #000 !important;
            background: transparent !important;
          }
        }
      }
    }

    .grid-container .vrtx-frontpage-box.large-video .vrtx-box-content {
      overflow: hidden;
      margin: 0 auto;
      width: 100%;
      background: white;

      div:first-of-type {
        margin-top: 0;
        margin-left: 0;
      }
    }

    .grid-container {
      margin-bottom: 0;
      padding: 60px 0 0;
    }

    .grid-container:last-child .vrtx-frontpage-box:last-child:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
      margin-bottom: 0;
    }

    .grid-container .small-width .vrtx-box-content {
      width: 100%;
    }

    .grid-container:not(.row-all-colored):not(.full-width-picture-bg):not(.row-all-no-margin-bottom) {
      padding-bottom: 60px;
    }

    .grid-container:not(.row-last-colored):not(.row-first-colored):not(.no-margin-top):not(.full-width-picture-bg) {
      padding-top: 60px;
    }

    .grid-container:last-child .vrtx-frontpage-box:last-child:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
      margin-bottom: 0;
    }
  }
}