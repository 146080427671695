/* Project frontpage */
/* load pretty project page */
// colors //
@import "../variables.less";

.project-overview {
  opacity: 0;
}

#vrtx-frontpage,
#vrtx-frontpage.vrtx-frontpage-two.not-for-ansatte {
  #main.project {
    margin-top: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    #total-main {
      margin-top: 100px;

      .vrtx-back {
        -webkit-animation: fadein 4s;
        animation: fadein 4s;
        opacity: 1;
        margin-bottom: 0;
        padding: 5px 0;
        width: 100%;
        position: absolute;
        background: @color-vrtx-back;

        a {
          width: 970px;
          margin-left: auto;
          margin-right: auto;
          display: block;
          color: @color-dark;

          &::before {
            display: inline-block;
            height: 1em;
            width: 1em;
            padding-right: 10px;
            background: url("../images/icon-chevron-back-black.svg") 0 0.2ex no-repeat;
            background-size: auto 100%;
            content: " ";
          }
        }
      }

      .grid-container.full-width-picture-bg {
        margin-top: 0;
        margin-bottom: 30px;
        -webkit-animation: fadein 1s;
        animation: fadein 1s;
      
        .full-width-picture {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          h1,
          h2 {
            color: @color-light;
            text-shadow: 0 1px 2px @color-heading-shadow;
            text-rendering: geometricPrecision;
            font-size: 7.5rem;
            line-height: 1.13 !important;
            margin-bottom: 40px;
            margin-top: 80px;
            width: 970px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 230px;
            font-weight: 600;
            font-family: "Hind", sans-serif;
          }

          .vrtx-frontpage-box-picture {
            display: none;
          }

          .vrtx-box-content {
            padding-bottom: 180px;
          }

          p {
            color: @color-light;
            font-size: 26px;
            line-height: 1.6;
            font-weight: 500;
            font-family: "Hind", sans-serif;
          }

          &.font-dark {
            .vrtx-box-content {
              h2,
              p {
                color: @color-dark;
              }
            }

            &.txt-bg p {
              line-height: 2;
              display: inline;
              background: @color-text-bg-light;
            }
          }

          &.font-light {
            .vrtx-box-content {
              h2,
              p {
                color: @color-light;
              }
            }

            &.txt-bg p {
              line-height: 2;
              display: inline;
              background: @color-text-bg-dark;
            }
          }
        }
      }

      .grid-container.grid-project-overview {
        margin-bottom: 0;

        &.last {
          margin-bottom: 30px;
        }

        .row {
          padding: 0;
        }

        // three accordions on top with project overview
        .vrtx-frontpage-box {
          &.publications {
            h2.accordion {
              border-bottom: none;
            }
          }

          &.key-info,
          &.publications {
            .vrtx-box-content p {
              font-size: 16px;
              font-weight: 500;
            }
          }

          &.key-info {
            .vrtx-box-content {
              .ui-accordion-content {
                padding: 0 20px 40px 31px;
              }
            }
          }

          &.project-overview {
            opacity: 1;

            h2.accordion {
              font-size: 20px;
              font-weight: 500;
            }

            .ui-accordion.ui-widget .ui-accordion-header .ui-icon {
              display: inline-block;
              background: url("../images/icon-chevron-right-black.svg") center left no-repeat;
              background-size: auto 100%;
              width: 30px;
              height: 1em;
              margin-bottom: -0.1em;
              margin-left: -30px;
              position: static;
            }

            .ui-accordion.ui-widget .ui-accordion-header.ui-accordion-header-active .ui-icon {
              background-image: url("../images/icon-chevron-down-black.svg");
            }
          }

          &.participants {
            &.project-overview ul {
              overflow: visible;
              display: flex;
              align-items: stretch;
              flex-wrap: wrap;
            }
            li {
              list-style: none;
              width: 50%;
              float: left;
              margin: 0;
              position: relative;
              height: 210px;
              background: @color-bg1;
              padding: 0 30px 0 0;
              color: @color-dark;
              font-family: "Hind", sans-serif;
              .vrtx-list-item-content {
                padding-left: 190px;
              }
              strong {
                display: block;
                padding-bottom: 20px;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 600;
                padding-top: 10px;
              }

              a:not(.item-title) {
                color: @color-dark;
                text-decoration: underline;
              }

              a.item-title,
              a.vrtx-image {
                color: @color-dark;
                pointer-events: none;
              }

              &::before {
                display: none;
              }

              .img-container {
                width: 37%;
                height: 100%;
                float: left;
                margin-right: 30px;
                background-size: cover !important;
                background-repeat: no-repeat !important;
              }

              img {
                width: 0;
                height: 0;
              }

              span {
                display: block;
                padding-top: 0;
              }

              &:nth-of-type(1) {
                background: @color-bg2;
              }

              &:nth-of-type(2) {
                background: @color-bg3;
              }

              &:nth-of-type(3) {
                background: @color-bg1;
              }
            }

            &.project-lead {
              li {
                width: 100%;
              }
            }
            
            &.participant-feed {
              .vrtx-box-content li {
                height: auto;
                  a.vrtx-image {
                    height: 100%;
                    padding-left: 170px;
                    // height: 0;
                    &:first-child {
                      margin: 0;
                    }
                    img {
                      height: 100%;
                      width: 170px;
                      object-fit: cover;
                      position: absolute;
                      left: 0;
                    }
                  }

                  a.item-title {
                    font-size: 20px;
                    font-weight: 600;
                    font-family: "Hind", sans-serif;
                    padding-top: 30px;

                    span {
                      font-size: 14px;
                      margin-top: -14px;
                    }
                  }

                  p {
                    font-size: 16px;
                    font-weight: 400;
                  }

                  &:nth-of-type(3n + 1) {
                    background: @color-bg2;
                  }

                  &:nth-of-type(3n + 2) {
                    background: @color-bg1;
                  }

                  &:nth-of-type(3n + 3) {
                    background: @color-bg3;
                  }
                  .vrtx-list-item-content .item-description {
                    padding-bottom: 20px;
                  }
                }
            }
          }

          &.project-overview {
            .ui-accordion.ui-widget .ui-accordion-header:first-child {
              border-top: none;
              border-bottom: none;
              border-bottom-width: 0;
              border-bottom-color: @color-light;
            }

            .ui-accordion.ui-widget {
              h2.ui-accordion-header {
                padding: 5px 0 5px 30px;
                border-top: 1px solid @color-bg1;
              }
            }

            &:nth-of-type(1) {
              .ui-accordion.ui-widget h2.ui-accordion-header {
                border-top: none;
              }
            }
          }

          &.project-overview.publications {
            .accordion-content {
              margin-bottom: 80px;
            }
          }
        }
        // end frontpage box
      }

      // end grid project overview
      // fix feeds:
      .vrtx-box-content .vrtx-feed {
        &.with-images ul li a.vrtx-image:first-child~:not(.vrtx-image) {
           margin-left: 0; 
           padding-top: 20px;
        }
        ul.items:after {
          display: none;
        }
      }

      .grid-container:last-of-type {
        margin-bottom: 80px;
      }

      .vrtx-frontpage-box {

        h1,
        h2 {
          color: @color-dark;
        }

        h2:not(.accordion) {
          font-size: 35px;
          font-size: 3.5rem;
          line-height: 1.5;
          font-weight: 600;
          padding-bottom: 20px;
        }

        .vrtx-box-content {
          h2.accordion {
            color: @color-dark;
          }

          p {
            font-size: 18px;
            line-height: 1.6;
            font-family: Roboto, sans-serif;
          }
        }
      }

      .font-weight-normal {
        .vrtx-box-content p {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}

/*@media screen and (max-width: 718px) {*/
@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  /* project top */
  #vrtx-frontpage.vrtx-frontpage-two.not-for-ansatte #main.project,
  #vrtx-frontpage #main.project {
    #total-main {
      margin-top: 0;

      .vrtx-back {
        top: 0;
        padding: 0;
        margin-bottom: 0;

        a {
          padding: 5px 20px;
        }
      }

      .grid-container.full-width-picture-bg {
        .row {
          .full-width-picture.project {
            .vrtx-frontpage-box-picture {
              display: block;
              opacity: 1;

              img {
                display: block;
                opacity: 1;
              }
            }
          }

          .font-light,
          .full-width-picture {
            .vrtx-box-content {
              h2 {
                width: 100%;
                margin: 0 0 20px;
                font-size: 4rem;
                padding-bottom: 0;
                color: @color-dark;
                text-shadow: none;
              }

              p {
                line-height: 1.5;
                color: @color-dark;
                display: inline-block;
                font-size: 20px;
                background: transparent;
                font-weight: 600;
              }
            }
          }
        }
      }

      .vrtx-frontpage-box h2 {
        font-size: 24px;
        font-size: 2.4rem;
        line-height: 34px;
        line-height: 3.4rem;

        &.accordion {
          font-size: 2rem;
        }
      }

      .project-overview {
        .accordion {
          border: none;
        }
      }

      .grid-container.grid-project-overview {
        .vrtx-frontpage-box.participants {
          &.project-overview ul {
            flex-direction: column;
            flex: 1;
            align-items: stretch;
            justify-content: center;
          }
          .vrtx-box-content {
            ul.items li {
              width: 100%;
              background: transparent !important;
              border-bottom: 4px solid @color-bg1;
              display: flex;
              flex-direction: row;
              align-items: stretch;
              justify-content: center;
              padding: 40px 0px !important;
              
              a.vrtx-image {
                width: 100px;
                height: 100%;
                padding-left: 0;
                flex: 1;
                
                img {
                  height: 100%;
                  width: 140px;
                  padding: 0;
                  position: relative;
                }
              }
              .vrtx-list-item-content {
                padding: 0;
                flex: 1;
                a.item-title {
                  padding-top: 0px;
                  margin-left: 140px;
                  margin-left: 0px;
                  span {
                    margin-top: 0;
                  }
                }
                .item-description {
                  margin-left: 140px;
                  margin-left: 0px;
                  p {
                    font-size: 16px;
                    line-height: 1.5;
                  }
                }
              }
            }
          }
        }
      }
    }
  } 
}
